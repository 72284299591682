import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const RenderModal = (props) => {

    const {show, fullscreen, title, header, centered, size, overflow, handleClose} = props

    return (
        <Modal 
            fullscreen={fullscreen}
            scrollable={true}
            size={size} 
            show={show} 
            onHide={handleClose} 
            aria-labelledby={centered ? "contained-modal-title-vcenter" : ""}
            centered={centered || false} 
            contentClassName={overflow ? `overflow-${overflow}` : ""}
        >
            { header || title ? 
                <Modal.Header>
                    {header ? header : (title && <Modal.Title>{title}</Modal.Title>)}
                    <Button variant="link" className="custom-close-btn" type="button" onClick={() => handleClose()}><i className="bi bi-x" style={{fontSize:"1.8em"}}></i></Button>
                </Modal.Header> : <div></div>
            }
            <Modal.Body className={overflow ? `overflow-${overflow}` : ""}>
                {props.children}
            </Modal.Body>
        </Modal>
    )
}

export default RenderModal;