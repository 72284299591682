import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';

const WelcomeBox = props => {
    const { 
        teamAlerts,
        tickets,
        handleClose
    } = props;

    return (
        <Container fluid style={{minHeight: "40vh"}} className="d-flex flex-column gap-3">
            <Row>
                <h3>Welcome back!</h3>
                <p className="fs-6 mb-0 lh-sm">
                    {"It's great to see you again! We hope you're having a fantastic day. Take a moment to check out the latest team alerts and urgencies below. Let's ensure everything runs smoothly for you. Remember, we're here to support you every step of the way. Let's make today a productive one!"}
                </p>
            </Row>
            <hr className="m-0" />
            <Row gap={3} className="h-100 g-2" >
                {
                    teamAlerts.length > 0 && (
                        <Col xs={12} md={tickets.length ? 6 : 12} className="d-flex flex-column overflow-auto">
                            <h4>{teamAlerts.length}&nbsp;Team Alerts!</h4>
                                    <Stack gap={2} className="overflow-auto" style={{maxHeight: "25vh"}}>
                                        { teamAlerts.map( tl => {
                                            return <Stack key={tl._id.toString()} className="text-capitalize border p-2 rounded">
                                                <div className="fs-6 m-0">
                                                    <strong><span className="text-secondary">{`${tl.createdBy.details.nickname ? tl.createdBy.details.nickname.toLowerCase() : tl.createdBy.details.fullName.toLowerCase()}: `}</span>{tl.concern}</strong>
                                                </div>
                                                <div className="d-flex justify-content-around text-small">
                                                    <div>
                                                        Created: {moment(tl.dateTime).format("L")}
                                                    </div>
                                                    <div>
                                                        Deadline: {moment(tl.dateTime).format("L")}
                                                    </div>
                                                </div>
                                            </Stack>
                                        })}
                                    </Stack>
                        </Col>
                    )
                }
                {
                    tickets.length > 0 && (
                        <Col xs={12} md={teamAlerts.length ? 6 : 12} className="d-flex flex-column overflow-auto">
                            <h4>{tickets.length}&nbsp;Urgent!</h4>
                                    <Stack gap={2} className="overflow-auto" style={{maxHeight: "25vh"}}>
                                        {tickets.map( ticket => {
                                            return <Stack key={ticket._id.toString()} className="text-capitalize border p-2 rounded">
                                                <div className="fs-6 m-0">
                                                    <strong><span className="text-secondary">{`${ticket.createdBy.details.nickname ? ticket.createdBy.details.nickname.toLowerCase() : ticket.createdBy.details.fullName.toLowerCase()}: `}</span>{ticket.problem}</strong>
                                                </div>
                                                { ticket.description && 
                                                    <div className="text-muted">
                                                        {ticket.description.replace(/\n/g, ' ')}
                                                    </div>
                                                }
                                                <div className="d-flex justify-content-around text-small">
                                                    <div>
                                                        Created: {moment(ticket.created).format("L")}
                                                    </div>
                                                </div>
                                            </Stack>
                                        })}
                                    </Stack>
                        </Col>
                    )
                }
            </Row>
            <Row className="d-grid pt-3 mt-auto">
                <Button type="button" variant="secondary" onClick={() => handleClose() }>Close</Button>
            </Row>
        </Container>
    )
}

export default WelcomeBox;