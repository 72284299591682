import axios from 'axios'
import moment from 'moment'

axios.defaults.withCredentials = true;

const BASE_URL = process.env.NODE_ENV === "development" ? (
  "http://localhost:3000"
) : (
  "https://admin.mycrashpro.com"
)

export { axios, moment, BASE_URL }