import { BASE_URL, axios, moment } from './config';


// counts
export const getPageCount = (pageNames, isServerCookies=null) => {
  if (isServerCookies) {
    // for getinitialprops
    return axios.get(`${BASE_URL}/admin/api/v1/page-counts/`,
      {
        credentials: 'include',
        headers: isServerCookies,
        params: { pageNames: pageNames }
      }
    ).then(res => res.data)
  } else {
    return axios.get(`${BASE_URL}/admin/api/v1/page-counts/`, { params: { pageNames: pageNames } }).then(res => res.data)
  }
}
export const getTabPageCount = (tabNames, isServerCookies=null) => {
  if (isServerCookies) {
    // for getinitialprops
    return axios.get(`${BASE_URL}/admin/api/v1/tab-page-counts/`,
      {
        credentials: 'include',
        headers: isServerCookies,
        params: { tabNames: tabNames }
      }
    ).then(res => res.data)
  } else {
    return axios.get(`${BASE_URL}/admin/api/v1/tab-page-counts/`, { params: { tabNames: tabNames } }).then(res => res.data)
  }
}
export const getATCount = () => {
  return axios.get(`${BASE_URL}/admin/api/v1/app-today-appts-count/`).then(res => res.data)
}
export const getUncompleteCount = (cookie=null) => {
  if (cookie) {
    // for getinitialprops
    return axios.get(`${BASE_URL}/admin/api/v1/inventory-uncomplete-count`,
      {
        credentials: 'include',
        headers: {
          Cookie: cookie,
        }
      }
    ).then(res => res.data)
  } else {
    return axios.get(`${BASE_URL}/admin/api/v1/inventory-uncomplete-count`).then(res => res.data)
  }
}
export const getWorkOrderCount = (cookie=null) => {
  if (cookie) {
    // for getinitialprops
    return axios.get(`${BASE_URL}/admin/api/v1/work-order-count`,
      {
        credentials: 'include',
        headers: {
          Cookie: cookie,
        }
      }
    ).then(res => res.data)
  } else {
    return axios.get(`${BASE_URL}/admin/api/v1/work-order-count`).then(res => res.data)
  }
}
export const getServiceIncomeCount = (cookie=null) => {
  if (cookie) {
    // for getinitialprops
    return axios.get(`${BASE_URL}/admin/api/v1/service-income-count`,
      {
        credentials: 'include',
        headers: {
          Cookie: cookie,
        }
      }
    ).then(res => res.data)
  } else {
    return axios.get(`${BASE_URL}/admin/api/v1/service-income-count`).then(res => res.data)
  }
}
export const getTeamAlertCount = (cookie=null) => {
  if (cookie) {
    // for getinitialprops
    return axios.get(`${BASE_URL}/admin/api/v1/teamAlert-count`,
      {
        credentials: 'include',
        headers: {
          Cookie: cookie,
        }
      }
    ).then(res => res.data)
  } else {
    return axios.get(`${BASE_URL}/admin/api/v1/teamAlert-count`).then(res => res.data)
  }
}
export const getBvaCount = (cookie=null) => {
  if (cookie) {
    // for getinitialprops
    return axios.get(`${BASE_URL}/admin/api/v1/bva-count`,
      {
        credentials: 'include',
        headers: {
          Cookie: cookie,
        }
      }
    ).then(res => res.data)
  } else {
    return axios.get(`${BASE_URL}/admin/api/v1/bva-count`).then(res => res.data)
  }
}

//appCenter deal tasks
export const postAppDealTask = (id, form) => {
  return axios.post(`${BASE_URL}/admin/api/v1/app-task/${id}`, form).then(res => res.data)
}
export const postAppDealTaskAction = (id, action, task) => {
  return axios.post(`${BASE_URL}/admin/api/v1/app-task-action/${id}`, { action: action, task: task }).then(res => res.data)
}

//appCenter deal wishlist
export const postWishlistVehicle = (id, form) => {
  return axios.post(`${BASE_URL}/admin/api/v1/app-wishlist/${id}`, form).then(res => res.data)
}
export const deleteWishlistVehicle = (id, form) => {
  const formId = form._id.toString();
  return axios.delete(`${BASE_URL}/admin/api/v1/app-wishlist/${id}/${formId}`).then(res => res.data)
}

//get stats
export const getInventoryStats = (form) => {
  return axios.get(`${BASE_URL}/admin/api/v1/inventory-stats`, { params: { form: form } }).then(res => res.data)
}
export const getAppStats = (weekCount) => {
  return axios.get(`${BASE_URL}/admin/api/v1/app-stats`, { params: { weekCount: weekCount } }).then(res => res.data)
}

export const getExportInventory = (form) => {
  return axios.get(`${BASE_URL}/admin/api/v1/inventory/export`, { params: { form: form } }).then(res => res.data)
}
export const getExportVehicle = (id) => {
  return axios.get(`${BASE_URL}/admin/api/v1/inventory/${id}/export`).then(res => res.data)
}
export const getExportServiceIncomes = (form) => {
  return axios.get(`${BASE_URL}/admin/api/v1/export/service-income`, { params: { form: form } }).then(res => res.data)
}

//blast responses
export const getBlastRecipients = () => {
  return axios.get(`${BASE_URL}/admin/api/v1/ringcentral/blast-recipient`).then(res => res.data)
}
export const postBlastRecipient = (form) => {
  return axios.post(`${BASE_URL}/admin/api/v1/ringcentral/blast-recipient`, form).then(res => res.data)
}
export const deleteBlastRecipient = (id) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/ringcentral/blast-recipient/${id}`).then(res => res.data)
}
//blast responses
export const getBlastResponses = () => {
  return axios.get(`${BASE_URL}/admin/api/v1/ringcentral/blast-response`).then(res => res.data)
}
export const getBlastRecords = () => {
  return axios.get(`${BASE_URL}/admin/api/v1/ringcentral/blast-records`).then(res => res.data)
}
export const postBlastResponse = (form) => {
  return axios.post(`${BASE_URL}/admin/api/v1/ringcentral/blast-response`, form).then(res => res.data)
}
export const editBlastResponse = (form) => {
  return axios.post(`${BASE_URL}/admin/api/v1/ringcentral/edit-blast-response`, form).then(res => res.data)
}
export const deleteBlastResponse = (id) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/ringcentral/blast-response/${id}`).then(res => res.data)
}
// blast schedules
export const getBlastSchedules = () => {
  return axios.get(`${BASE_URL}/admin/api/v1/ringcentral/blast-schedule`).then(res => res.data)
}
export const postScheduleBlastSMS = (form) => {
  return axios.post(`${BASE_URL}/admin/api/v1/ringcentral/blast-schedule`, form).then(res => res.data)
}
export const deleteBlastSchedule = (id) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/ringcentral/blast-schedule/${id}`).then(res => res.data)
}
//blast center
export const getBlastAppCount = (form) => {
  return axios.get(`${BASE_URL}/admin/api/v1/ringcentral/blast-apps`, { params: form }).then(res => res.data)
}
export const getBlastStatus = () => {
  return axios.get(`${BASE_URL}/admin/api/v1/ringcentral/blast`).then(res => res.data)
}
export const postBlastSMS = (form) => {
  return axios.post(`${BASE_URL}/admin/api/v1/ringcentral/blast`, form).then(res => res.data)
}
export const cancelBlast = () => {
  return axios.put(`${BASE_URL}/admin/api/v1/cancel-blast`).then(res => res.data)
}

//Web Push Subscription
export const postPushSubscription = (credentials) => {
  return axios.post(`${BASE_URL}/admin/api/v1/pwa/subscription/`, credentials).then(res => res.data)
}
export const updateSubscription = (credentials) => {
  return axios.post(`${BASE_URL}/admin/api/v1/pwa/update-subscription/`, credentials).then(res => res.data)
}

//ringcentral routes
export const postRingCentralRingOut = (form) => {
  return axios.post(`${BASE_URL}/admin/api/v1/ringcentral-ringout/`, { params: form } ).then(res => res.data)
}
export const getRingcentralMessages = (form) => {
  return axios.get(`${BASE_URL}/admin/api/v1/ringcentral/`, { params: form } ).then(res => res.data)
}

export const getRingcentralMessageContent = (form) => {
  return axios.get(`${BASE_URL}/admin/api/v1/ringcentral/content/`, { params: form }).then(res => res.data)
}

export const readRingcentralMessage = (convId, record) => {
  return axios.put(`${BASE_URL}/admin/api/v1/ringcentral/${convId}`, { record }).then(res => res.data)
}

export const postRingcentralMessage = (form) => {
  return axios.post(`${BASE_URL}/admin/api/v1/ringcentral/`, form).then(res => res.data)
}

//homepage slider images routes
export const getSliderImages = () => {
  return axios.get(`${BASE_URL}/api/v1/slider`).then(res => res.data)
}

//vehicle routes
export const getVehicles = (form) => {
  return axios.get(`${BASE_URL}/admin/api/v1/vehicles`, { params: form }).then(res => res.data).catch(err => console.error(err?.response?.data ?? err))
}

export const getVehiclesByCategory = (categoryQuery, limit, offset, filterSpecial) => {
  return axios.get(`${BASE_URL}/api/v1/vehicles/category`, { params: {categoryQuery: categoryQuery, limit: limit, offset: offset, filterSpecial: filterSpecial} }).then(res => res.data)
}

export const getRecentVehicles = (amount) => {
  return axios.get(`${BASE_URL}/api/v1/vehicles/recent/${amount}`).then(res => res.data)
}

export const createVehicle = (vehicle) => {
  return axios.post(`${BASE_URL}/admin/api/v1/vehicles`, vehicle).then(res => res.data)
}

export const bumpVehicle = (id) => {
  return axios.patch(`${BASE_URL}/admin/api/v1/bump-vehicle/${id}`).then(res => res.data)
}

export const postFeatVehicle = (id) => {
  return axios.post(`${BASE_URL}/admin/api/v1/featured-vehicles/${id}`).then(res => res.data)
}

export const removeFeatVehicle = (id) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/featured-vehicles/${id}`).then(res => res.data)
}

export const getVehicleById = (id) => {
  return axios.get(`${BASE_URL}/api/v1/vehicles/${id}`).then(res => res.data)
}

export const updateVehicle = (vehicle) => {
  return axios.post(`${BASE_URL}/admin/api/v1/vehicles/${vehicle._id}`, vehicle).then(res => res.data)
}

export const deleteVehicle = (id) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/vehicles/${id}`).then(res => res.data)
}

export const getPosts = () => {
  return axios.get(`${BASE_URL}/api/v1/posts`).then(res => res.data)
}
// vehicle sharing routes
//carzing
export const postVehicleCarZing = (id) => {
  return axios.post(`${BASE_URL}/admin/api/v1/carzing`, { id: id } ).then(res => res.data)
}
export const deleteVehicleCarZing = (id) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/carzing/${id}`).then(res => res.data)
}
//v12
export const postVehicleV12 = (id) => {
  return axios.post(`${BASE_URL}/admin/api/v1/v12`, { id: id } ).then(res => res.data)
}
export const deleteVehicleV12 = (id) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/v12/${id}`).then(res => res.data)
}
//offerup
export const postVehicleOfferUp = (id) => {
  return axios.post(`${BASE_URL}/admin/api/v1/offerup`, { id: id } ).then(res => res.data)
}
export const deleteVehicleOfferUp = (id) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/offerup/${id}`).then(res => res.data)
}

//auth routes
export const postLogin = (loginCredentials) => {
  return axios.post(`${BASE_URL}/api/v1/login`, loginCredentials).then(res => res.data)

}

export const postLogout = () => {
  return axios.post(`${BASE_URL}/api/v1/logout`).then(res => res.data).catch(err => console.error(err))
}

export const getLogin = (cookie=null) => {
  if (cookie) {
    // for _app.js getinitialprops
    return axios.get(`${BASE_URL}/api/v1/login`,
      {
        credentials: 'include',
        headers: cookie // { cookie; ''}
      }
    ).then(res => {
      return res.data})
  } else {
    return axios.get(`${BASE_URL}/api/v1/login`).then(res => res.data)
  }
}

// for canceled dupe search
let controller;
//application routes
export const getSearchApplications = (limit, offset, query, sortBy, filterItems) => {
  const params = { 
    limit: limit, 
    offset: offset, 
    value: sortBy.value,
    type: sortBy.type,
    search: query,
    filterItems: filterItems
  }
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();
  return axios.get(`${BASE_URL}/admin/api/v1/app`, { params: params, signal: controller.signal }).then(res => res.data)
}

export const getExportApplications = (form) => {
  return axios.get(`${BASE_URL}/admin/api/v1/apps/export`, { params: form }).then(res => res.data)
}

export const getApplications = (limit, offset, sortBy, filterItems) => {
  const params = { 
    limit: limit, 
    offset: offset, 
    value: sortBy.value,
    type: sortBy.type,
    filterItems: filterItems
  }
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();
  return axios.get(`${BASE_URL}/admin/api/v1/apps`, { params: params, signal: controller.signal}).then(res => res.data)
}

export const getBasicAppStats = () => {
  const today = moment().toISOString();
  return axios.get(`${BASE_URL}/admin/api/v1/basic-app-stats/`, { params: {today: today}}).then(res => res.data)
}

export const getHLCount = () => {
  return axios.get(`${BASE_URL}/admin/api/v1/app-hot-count/`).then(res => res.data)
}

export const getApplicationById = (id, isServerCookies) => {
  return axios.get(`${BASE_URL}/admin/api/v1/apps/${id}`, { headers: isServerCookies }).then(res => res.data)
}

export const postApplicationStatus = (id, status, statusType) => {
  return axios.post(`${BASE_URL}/admin/api/v1/app-status/${id}`, { status: status, statusType: statusType }).then(res => res.data)
}

export const deleteApplication = (id) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/apps/${id}`).then(res => res.data)
}

export const editApplication = (application) => {
  return axios.post(`${BASE_URL}/admin/api/v1/app/${application._id}`, application).then(res => res.data)
}

export const postApplicationComment = (id, form, flag) => {
  return axios.post(`${BASE_URL}/admin/api/v1/apps/${id}`, form, { params: {flag: flag}}).then(res => res.data)
}

export const postApplication = (application) => {
  return axios.post(`${BASE_URL}/api/v1/apps`, application).then(res => res.data)
}

//upload media routes
export const postInventoryFiles = (files, onUploadProgress) => {
  return axios.post(`${BASE_URL}/admin/api/v1/files/inventory`, files, { 
    params: {suffix: 'temp', form: 'InventoryFileForm'}, 
    onUploadProgress
  }).then(res => res.data)
}
export const postContactFiles = (files, onUploadProgress) => {
  return axios.post(`${BASE_URL}/admin/api/v1/files/contacts`, files, { 
    params: {suffix: 'temp', form: 'ContactForm'}, 
    onUploadProgress
  }).then(res => res.data)
}
export const postRingcentralFiles = (files, onUploadProgress) => {
  return axios.post(`${BASE_URL}/admin/api/v1/files/ringcentral`, files, { 
    params: {suffix: 'temp', form: 'RingcentralForm'}, 
    onUploadProgress
  }).then(res => res.data)
}
export const postVehicleImages = (images, onUploadProgress) => {
  return axios.post(`${BASE_URL}/admin/api/v1/images/vehicles`, images, { 
    params: {suffix: 'temp', form: 'VehicleCreateForm'}, 
    onUploadProgress
  }).then(res => res.data)
}
export const postWorkOrderImages = (images, onUploadProgress) => {
  return axios.post(`${BASE_URL}/admin/api/v1/images/workorders`, images, { 
    params: {suffix: 'temp', form: 'WorkOrderPartForm'}, 
    onUploadProgress
  }).then(res => res.data)
}
export const postBlastImage = (images, onUploadProgress) => {
  return axios.post(`${BASE_URL}/admin/api/v1/images/blast`, images, { 
    params: {suffix: 'blastTemp', form: 'BlastForm'}, 
    onUploadProgress
  }).then(res => res.data)
}
export const deleteBlastImages = (form) => {
  return axios.post(`${BASE_URL}/admin/api/v1/images/blast/delete`, form).then(res => res.data)
}
export const postSliderImages = (images) => {
  return axios.post(`${BASE_URL}/admin/api/v1/images/slider`, images, { params: {suffix: 'Slider', form: 'SliderEditForm'}}).then(res => res.data)
}
export const deleteFiles = (files) => {
  return axios.post(`${BASE_URL}/admin/api/v1/delete`, { files: files }).then(res => res.data)
}

//repair routes
export const editInventoryFiles = (id, files) => {
  return axios.post(`${BASE_URL}/admin/api/v1/inventory-files/${id}`, files).then(res => res.data);
}
export const getRepairList = (limit, offset, sortBy, filterItems) => {
    const params = typeof sortBy !== "undefined" ? { limit: limit, offset: offset, value: sortBy.value, type: sortBy.type, filterItems: filterItems  } : { limit: limit, offset: offset, filterItems: filterItems }
    
    if (controller) {
      controller.abort();
    }
    controller = new AbortController();

    const config = {
      params: params,
      signal: controller.signal
    }

    return axios.get(`${BASE_URL}/admin/api/v1/repair-list`, config ).then(res => res.data)
}

export const getSearchRepairs = (limit, offset, query, sortBy, filterItems) => {
  const params = { 
    limit: limit, 
    offset: offset, 
    value: sortBy.value,
    type: sortBy.type,
    search: query,
    filterItems: filterItems
  }

  if (controller) {
    controller.abort();
  }
  controller = new AbortController();

  const config = {
    params: params,
    signal: controller.signal
  }

  return axios.get(`${BASE_URL}/admin/api/v1/repair`, config ).then(res => res.data)
}

export const postRepair = (repair) => {
  return axios.post(`${BASE_URL}/admin/api/v1/repair`, repair).then(res => res.data).catch(err => console.error(err))
}
export const postInventoryBatch = (file, onUploadProgress) => {
  return axios.post(`${BASE_URL}/admin/api/v1/files/inventory/batch-import`, file, { 
    params: {suffix: 'temp', form: 'InventoryBatchForm'}, 
    onUploadProgress
  }).then(res => res.data)
}

export const editRepair = (repair) => {
  return axios.post(`${BASE_URL}/admin/api/v1/repair/${repair._id}`, repair).then(res => res.data)
}

// export const editInventoryWarrantyOrGap = (inventoryId, form) => {
//   return axios.post(`${BASE_URL}/admin/api/v1/inventory-${form.source}/${inventoryId}`, form).then(res => res.data)
// }
export const editRepairGps = (repairId, gpsForm) => {
  return axios.post(`${BASE_URL}/admin/api/v1/repair-gps/${repairId}`, gpsForm).then(res => res.data)
}

export const editRepairReadyDate = (repairId, readyDate, readyType=null) => {
  return axios.post(`${BASE_URL}/admin/api/v1/repair-ready-date/${repairId}`, {readyDate: readyDate, readyType: readyType}).then(res => res.data)
}

export const deleteRepair = (id) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/repair/${id}`).then(res => res.data)
}

export const getRepairById = (id, isServerCookies) => {
  return axios.get(`${BASE_URL}/admin/api/v1/repairs/${id}`, { headers: isServerCookies }).then(res => res.data)
}

export const patchRepair = (id, form) => {
  return axios.patch(`${BASE_URL}/admin/api/v1/update-repair/${id}`, form).then(res => res.data)
}

export const patchRepairDateToday = (id, key) => {
  return axios.patch(`${BASE_URL}/admin/api/v1/update-repair-date-today/${id}/${key}`).then(res => res.data)
}

export const postReadyRepair = (id, status) => {
  return axios.post(`${BASE_URL}/admin/api/v1/repair-ready/`, {id: id, status: status}).then(res => res.data)
}
export const postUrgentRepair = (id, level) => {
  return axios.post(`${BASE_URL}/admin/api/v1/repair-urgent/`, {id: id, level: level}).then(res => res.data)
}

export const postRepairComment = (id, comments, flag) => {
  return axios.post(`${BASE_URL}/admin/api/v1/repair/comments/${id}`, comments, { params: {flag: flag}}).then(res => res.data)
}
// Inventory GetBacks
export const getGetBacks = (id) => {
  return axios.get(`${BASE_URL}/admin/api/v1/inventory/${id}/get-backs`).then(res => res.data)
}
export const incGetBack = (id) => {
  return axios.patch(`${BASE_URL}/admin/api/v1/inventory/${id}/get-backs`).then(res => res.data)
}
//repa Inventory Deals
export const getCanceledDeals = (id) => {
  return axios.post(`${BASE_URL}/admin/api/v1/inventory/${id}/canceled-deals`).then(res => res.data).catch(err => console.error(err))
}
// Inventory Locations
export const getLocationCount = (id) => {
  return axios.get(`${BASE_URL}/admin/api/v1/inventory/${id}/locations/count`).then(res => res.data)
}
export const getLocations = (id) => {
  return axios.get(`${BASE_URL}/admin/api/v1/inventory/${id}/locations`).then(res => res.data)
}
export const postLocation = (id, form) => {
  return axios.post(`${BASE_URL}/admin/api/v1/inventory/${id}/location`, form).then(res => res.data)
}
export const activeLocation = (id, locId ) => {
  return axios.patch(`${BASE_URL}/admin/api/v1/inventory/${id}/location/${locId}`).then(res => res.data)
}
export const deleteLocation = (id, locId ) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/inventory/${id}/location/${locId}`).then(res => res.data)
}

//work order routes
export const getWorkOrderList = (limit, offset, sortBy, filterItems, isServerCookies=null) => {
  const params = typeof sortBy !== "undefined" ? { limit: limit, offset: offset, value: sortBy.value, type: sortBy.type, filterItems: filterItems  } : { limit: limit, offset: offset, filterItems: filterItems }
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();
  return axios.get(`${BASE_URL}/admin/api/v1/work-order-list`, { params: params, signal: controller.signal, headers: isServerCookies}).then(res => res.data)
}
export const getSearchWorkOrders = (limit, offset, query, sortBy, filterItems) => {
  const params = { 
    limit: limit, 
    offset: offset, 
    value: sortBy.value,
    type: sortBy.type,
    search: query,
    filterItems: filterItems
  }
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();
  return axios.get(`${BASE_URL}/admin/api/v1/work-order`, { params: params, signal: controller.signal } ).then(res => res.data)
}

export const postWorkOrder = (workOrder) => {
  return axios.post(`${BASE_URL}/admin/api/v1/work-order`, workOrder).then(res => res.data).catch(err => console.error(err))
}
export const editWorkOrder = (workOrder) => {
  return axios.post(`${BASE_URL}/admin/api/v1/work-order/${workOrder._id}`, workOrder).then(res => res.data)
}
export const deleteWorkOrder = (id) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/work-order/${id}`).then(res => res.data)
}
export const postAcknowledgeWorkOrder = (id, status) => {
  return axios.post(`${BASE_URL}/admin/api/v1/work-order-ack/`, {id: id, status: status}).then(res => res.data)
}
export const postWorkOrderComment = (id, comments, flag) => {
  return axios.post(`${BASE_URL}/admin/api/v1/work-order/comments/${id}`, comments, { params: {flag: flag}}).then(res => res.data)
}
export const updateWorkOrderStatus = (id, newStatus) => {
  return axios.patch(`${BASE_URL}/admin/api/v1/work-order/${id}/status`, {status: newStatus}).then(res => res.data).catch(err => console.error(err))
}
// suggestions
export const updateWorkOrderSuggestion = (id, suggestionForm) => {
  // suggestionForm = { suggestionId: objectID, needed: Number // 0,1,2 }
  return axios.patch(`${BASE_URL}/admin/api/v1/work-order/${id}/suggestions`, suggestionForm).then(res => res.data)
}
export const postWorkOrderSuggestion = (id, suggestionForm) => {
  return axios.post(`${BASE_URL}/admin/api/v1/work-order/${id}/suggestions`, suggestionForm).then(res => res.data)
}
export const deleteWorkOrderSuggestion = (id, suggestionId) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/work-order/suggestions/${id}`,  {params: { suggestionId: suggestionId } }).then(res => res.data).catch(err => console.error(err))
}
// parts
export const postWorkOrderPart = (id, partForm) => {
  return axios.post(`${BASE_URL}/admin/api/v1/work-order/${id}/parts`, partForm).then(res => res.data).catch(err => console.error(err))
}
export const deleteWorkOrderPart = (id, partId) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/work-order/parts/${id}`,  {params: { partId: partId } }).then(res => res.data).catch(err => console.error(err))
}

// contacts routes
export const getContactList = (limit, offset, sortBy, filterItems, isServerCookies=null) => {
  const params = typeof sortBy !== "undefined" ? { limit: limit, offset: offset, value: sortBy.value, type: sortBy.type, filterItems: filterItems  } : { limit: limit, offset: offset, filterItems: filterItems }
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();
  return axios.get(`${BASE_URL}/admin/api/v1/contact-list`, { params: params, signal: controller.signal, headers: isServerCookies}).then(res => res.data)
}
export const getSearchContacts = (limit, offset, query, sortBy, filterItems) => {
  const params = { 
    limit: limit, 
    offset: offset, 
    value: sortBy.value,
    type: sortBy.type,
    search: query,
    filterItems: filterItems
  }
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();
  return axios.get(`${BASE_URL}/admin/api/v1/contact`, { params: params, signal: controller.signal } ).then(res => res.data)
}

export const postContact = (contact) => {
  return axios.post(`${BASE_URL}/admin/api/v1/contact`, contact).then(res => res.data).catch(err => console.error(err))
}
export const editContact = (contact) => {
  return axios.post(`${BASE_URL}/admin/api/v1/contact/${contact._id}`, contact).then(res => res.data)
}
export const deleteContact = (id) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/contact/${id}`).then(res => res.data)
}
export const postAcknowledgeContact = (id, status) => {
  return axios.post(`${BASE_URL}/admin/api/v1/contact-ack/`, {id: id, status: status}).then(res => res.data)
}
export const postContactComment = (id, comments, flag) => {
  return axios.post(`${BASE_URL}/admin/api/v1/contact/comments/${id}`, comments, { params: {flag: flag}}).then(res => res.data)
}

//work order routes
export const getServiceIncomeList = (limit, offset, sortBy, filterItems) => {
  const params = typeof sortBy !== "undefined" ? { limit: limit, offset: offset, value: sortBy.value, type: sortBy.type, filterItems: filterItems  } : { limit: limit, offset: offset, filterItems: filterItems }
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();
  return axios.get(`${BASE_URL}/admin/api/v1/service-income-list`, { params: params, signal: controller.signal} ).then(res => res.data)
}
export const getSearchServiceIncomes = (limit, offset, query, sortBy, filterItems) => {
  const params = { 
    limit: limit, 
    offset: offset, 
    value: sortBy.value,
    type: sortBy.type,
    search: query,
    filterItems: filterItems
  }
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();
  return axios.get(`${BASE_URL}/admin/api/v1/service-income`, { params: params, signal: controller.signal } ).then(res => res.data)
}


//work order routes
export const checkServiceIncomeVin = (vin) => {
  return axios.get(`${BASE_URL}/admin/api/v1/service-income-check/${vin}`).then(res => res.data)
}

export const postServiceIncome = (serviceIncome) => {
  return axios.post(`${BASE_URL}/admin/api/v1/service-income`, serviceIncome).then(res => res.data)
}
export const editServiceIncome = (serviceIncome) => {
  return axios.post(`${BASE_URL}/admin/api/v1/service-income/${serviceIncome._id}`, serviceIncome).then(res => res.data)
}
export const deleteServiceIncome = (id) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/service-income/${id}`).then(res => res.data)
}
export const postAcknowledgeServiceIncome = (id, status) => {
  return axios.post(`${BASE_URL}/admin/api/v1/service-income-ack/`, {id: id, status: status}).then(res => res.data)
}
export const postServiceIncomeComment = (id, comments, flag) => {
  return axios.post(`${BASE_URL}/admin/api/v1/service-income/comments/${id}`, comments, { params: {flag: flag}}).then(res => res.data)
}
export const postServiceIncomeRepair = (id, form, listName) => {
  return axios.post(`${BASE_URL}/admin/api/v1/service-income/${id}/${listName}`, form).then(res => res.data).catch(err => console.error(err))
}
export const editServiceIncomeRepair = (id, form, listName) => {
  return axios.patch(`${BASE_URL}/admin/api/v1/service-income/${id}/${listName}`, form).then(res => res.data).catch(err => console.error(err))
}
export const deleteServiceIncomeRepair = (id, itemId, listName) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/service-income/${id}/${listName}`,  {params: { itemId: itemId } }).then(res => res.data).catch(err => console.error(err))
}

//teamAlert routes
export const getTeamAlertList = (limit, offset, sortBy, filterItems) => {
  const params = typeof sortBy !== "undefined" ? { limit: limit, offset: offset, value: sortBy.value, type: sortBy.type, filterItems: filterItems  } : { limit: limit, offset: offset, filterItems: filterItems }
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();
  return axios.get(`${BASE_URL}/admin/api/v1/teamAlert-list`, { params: params, signal: controller.signal } ).then(res => res.data)
}
export const getVehicleTeamAlerts = (id) => {
  return axios.get(`${BASE_URL}/admin/api/v1/repair-teamAlerts/${id}`).then(res => res.data)
}
export const getSearchTeamAlerts = (limit, offset, query, sortBy, filterItems) => {
  const params = { 
    limit: limit,
    offset: offset,
    value: sortBy.value,
    type: sortBy.type,
    search: query,
    filterItems: filterItems
  }
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();
  return axios.get(`${BASE_URL}/admin/api/v1/teamAlert`, { params: params, signal: controller.signal } ).then(res => res.data)
}

export const postTeamAlert = (teamAlert) => {
  return axios.post(`${BASE_URL}/admin/api/v1/teamAlert`, teamAlert).then(res => res.data).catch(err => console.error(err))
}
export const editTeamAlert = (teamAlert) => {
  return axios.post(`${BASE_URL}/admin/api/v1/teamAlert/${teamAlert._id}`, teamAlert).then(res => res.data)
}
export const deleteTeamAlert = (id) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/teamAlert/${id}`).then(res => res.data)
}
export const postAcknowledgeTeamAlert = (form) => {
  return axios.post(`${BASE_URL}/admin/api/v1/teamAlert-ack/`, form).then(res => res.data)
}
export const postTeamAlertComment = (id, comments, flag) => {
  return axios.post(`${BASE_URL}/admin/api/v1/teamAlert/comments/${id}`, comments, { params: {flag: flag}}).then(res => res.data)
}

//bva routes
export const getBvaList = (limit, offset, sortBy, filterItems) => {
  const params = typeof sortBy !== "undefined" ? { limit: limit, offset: offset, value: sortBy.value, type: sortBy.type, filterItems: filterItems  } : { limit: limit, offset: offset, filterItems: filterItems }
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();
  return axios.get(`${BASE_URL}/admin/api/v1/bva-list`, { params: params, signal: controller.signal } ).then(res => res.data)
}
export const getVehicleBvas = (id) => {
  return axios.get(`${BASE_URL}/admin/api/v1/repair-bvas/${id}`).then(res => res.data)
}
export const getSearchBvas = (limit, offset, query, sortBy, filterItems) => {
  const params = { 
    limit: limit, 
    offset: offset, 
    value: sortBy.value,
    type: sortBy.type,
    search: query,
    filterItems: filterItems
  }
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();
  return axios.get(`${BASE_URL}/admin/api/v1/bva`, { params: params, signal: controller.signal } ).then(res => res.data)
}
export const postBva = (bva) => {
  return axios.post(`${BASE_URL}/admin/api/v1/bva`, bva).then(res => res.data).catch(err => console.error(err))
}
export const editBva = (bva) => {
  return axios.post(`${BASE_URL}/admin/api/v1/bva/${bva._id}`, bva).then(res => res.data)
}
export const deleteBva = (id) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/bva/${id}`).then(res => res.data)
}
export const postAcknowledgeBva = (form) => {
  return axios.post(`${BASE_URL}/admin/api/v1/bva-ack/`, form).then(res => res.data)
}
export const postBvaComment = (id, comments, flag) => {
  return axios.post(`${BASE_URL}/admin/api/v1/bva/comments/${id}`, comments, { params: {flag: flag}}).then(res => res.data)
}

//user routes
export const getUserList = (limit, offset, sortBy, filterItems) => {
  const params = typeof sortBy !== "undefined" ? { limit: limit, offset: offset, value: sortBy.value, type: sortBy.type, filterItems: filterItems } : { limit: limit, offset: offset, filterItems: filterItems }
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();
  return axios.get(`${BASE_URL}/admin/api/v1/user-list`, { params: params, signal: controller.signal } ).then(res => res.data)
}
export const getSearchUsers = (limit, offset, query, sortBy, filterItems) => {
  const params = { 
    limit: limit, 
    offset: offset, 
    value: sortBy.value,
    type: sortBy.type,
    search: query,
    filterItems: filterItems
  }
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();
  return axios.get(`${BASE_URL}/admin/api/v1/user`, { params: params, signal: controller.signal } ).then(res => res.data)
};
export const postUser = (user) => {
  return axios.post(`${BASE_URL}/admin/api/v1/user`, user).then(res => res.data)
}
export const editUser = (user) => {
  return axios.post(`${BASE_URL}/admin/api/v1/user/${user._id}`, user).then(res => res.data)
}
export const deleteUser = (id) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/user/${id}`).then(res => res.data)
}
export const getUserById = (id, isServerCookies) => {
  return axios.get(`${BASE_URL}/admin/api/v1/users/${id}`, { headers: isServerCookies }).then(res => res.data)
}
export const getUserDetails = (id, isServerCookies=null) => {
  return axios.get(`${BASE_URL}/admin/api/v1/users-details/${id}`, { headers: isServerCookies }).then(res => res.data)
}
//timesheets
export const getUserTimesheets = (userId, fromDate, toDate, isServerCookies) => {
  return axios.get(`${BASE_URL}/admin/api/v1/timesheets/${userId}`, { headers: isServerCookies, params: { fromDate, toDate } }).then(res => res.data)
}
export const postUserTimesheet = (userId, timesheetData, timeclock=false) => {
  return axios.post(`${BASE_URL}/admin/api/v1/timesheet/${userId}`, { ...timesheetData, timeclock: timeclock }).then(res => res.data)
}
export const deleteUserTimesheet = (timesheetId) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/timesheet/${timesheetId}`).then(res => res.data).catch(err => console.error(err))
}
//pinned apps
export const postUserPinApp = (id, makeSuper, pinType) => {
  return axios.post(`${BASE_URL}/admin/api/v1/user/pinned/${pinType}`, { id: id, makeSuper: makeSuper }).then(res => res.data)
}
export const deleteUserPinApp = (id, pinType) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/user/pinned/${pinType}/${id}`).then(res => res.data)
}
//user notifications
export const getUserNotifications = () => {
  return axios.get(`${BASE_URL}/admin/api/v1/user/notifications`).then(res => res.data)
}
export const editUserNotifications = () => {
  return axios.patch(`${BASE_URL}/admin/api/v1/user/notifications`).then(res => res.data)
}
//hidden calendars
export const editUserHiddenCalendars = (data) => {  // data: { calendarId: "", hide: True / False }
  return axios.patch(`${BASE_URL}/admin/api/v1/user/hidden-calendars`, data).then(res => res.data);
}

//siteAlert center routes
export const postSiteAlert = (siteAlertForm) => {
  return axios.post(`${BASE_URL}/admin/api/v1/siteAlert/`, siteAlertForm).then(res => res.data).catch(err => console.error(err))
}
export const getSiteAlert = () => {
  return axios.get(`${BASE_URL}/admin/api/v1/siteAlert/`).then(res => res.data)
}
export const deleteSiteAlert = (id) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/siteAlert/${id}`).then(res => res.data)
}

//dad joke
export const getRandomDadJoke = () => {
  return axios.get(`${BASE_URL}/admin/api/v1/joke/`).then(res => res.data)
}

//customFormEntries
export const getFormEntries = (category) => {
  return axios.get(`${BASE_URL}/admin/api/v1/form-entries/${category}`).then(res => res.data);
}
export const postFormEntry = (form) => {
  return axios.post(`${BASE_URL}/admin/api/v1/form-entries/`, form).then(res => res.data);
}
export const deleteFormEntry = (category, entryName, entryNameRef, formCategoryRef) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/form-entries/${category}`, { params: { entryName: entryName, entryNameRef: entryNameRef, formCategoryRef: formCategoryRef } }).then(res => res.data)
}

//reportCenter
export const getInventoryReport = (filterItems) => {
  return axios.get(`${BASE_URL}/admin/api/v1/reports/inventory`, { params: { filterItems: filterItems } }).then(res => res.data);
}

// inventory GPS
// for canceled dupe search
let gpsController;
export const locateGPS = (serialNumber, manualUpdate=false, abort=false) => {
  if (abort && gpsController) {
    gpsController.abort();
    return Promise.reject('canceled');
  } else if (gpsController) {
    gpsController.abort();
  }
  gpsController = new AbortController();
  return axios.get(`${BASE_URL}/admin/api/v1/gps/${serialNumber}`, { params: { manualUpdate: manualUpdate }, signal: gpsController.signal }).then(res => res.data);
}

export const getRecentCommentsList = () => {
  return axios.get(`${BASE_URL}/admin/api/v1/dashboard/recent-comments/`).then(res => res.data)
}

export const getAppVersion = () => {
  return axios.get(`${BASE_URL}/api/v1/version`).then(res => res.data)
}

export const getEvents = (params) => {
  return axios.get(`${BASE_URL}/admin/api/v1/calendars/events`, { params: params }).then(res => res.data)
}
export const getCalendars = (params) => {
  return axios.get(`${BASE_URL}/admin/api/v1/calendars`, { params: params }).then(res => res.data)
}
export const postCalendar = (form) => {
  return axios.post(`${BASE_URL}/admin/api/v1/calendars/`, form).then(res => res.data);
}
export const editCalendar = (data) => {
  return axios.patch(`${BASE_URL}/admin/api/v1/calendar/${data.calendar._id}`, data).then(res => res.data);
}
export const deleteCalendar = (id) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/calendar/${id}`).then(res => res.data)
}

export const postEvent = (data) => {
  return axios.post(`${BASE_URL}/admin/api/v1/calendar/${data.event.calendar}/events`, data).then(res => res.data);
}
export const editEvent = (data) => {
  return axios.patch(`${BASE_URL}/admin/api/v1/calendar/${data.event.calendar}/event/${data.event._id}`, data).then(res => res.data);
}
export const deleteEvent = (data) => {
  return axios.patch(`${BASE_URL}/admin/api/v1/calendar/${data.event.calendar._id}/delete-event/${data.event._id}`, { startDate: data.startDate, endDate: data.endDate }).then(res => res.data);
}


// quick stats
export const getQuickStats = (params) => {
  return axios.get(`${BASE_URL}/admin/api/v1/dashboard/quick-stats`, { params: params }).then(res => res.data)
}

// deals

// for canceled dupe search
let dealController;
//application routes
export const getSearchDeals = (limit, offset, query, sortBy={}, filterItems={}) => {
  const params = { 
    limit:        limit, 
    offset:       offset, 
    value:        sortBy.value,
    type:         sortBy.type,
    search:       query,
    filterItems:  filterItems
  }
  if (dealController) {
    dealController.abort();
  }
  dealController = new AbortController();
  return axios.get(`${BASE_URL}/admin/api/v1/inventory/deal`, { params: params, signal: dealController.signal }).then(res => res.data)
}

export const getLeaderboard = (params) => {
  return axios.get(`${BASE_URL}/admin/api/v1/inventory/deals-leaderboard`, { params: params }).then(res => res.data)
}
export const getDeals = (params) => {
  if (dealController) {
    dealController.abort();
  }
  dealController = new AbortController();
  return axios.get(`${BASE_URL}/admin/api/v1/inventory/deals`, { params: params, signal: dealController.signal }).then(res => res.data)
}
export const postDeal = (form) => {
  return axios.post(`${BASE_URL}/admin/api/v1/inventory/deals`, form).then(res => res.data);
}
export const editDeal = (form) => {
  return axios.patch(`${BASE_URL}/admin/api/v1/inventory/deal/${form._id}`, form).then(res => res.data);
}
export const cancelDeal = (form) => {
  return axios.patch(`${BASE_URL}/admin/api/v1/inventory/deal-cancelation/${form._id}`, form).then(res => res.data);
}
export const deleteDeal = (id) => {
  return axios.delete(`${BASE_URL}/admin/api/v1/inventory/deal/${id}`).then(res => res.data)
}

// tickets
export const getTickets = (params) => {
  return axios.get(`${BASE_URL}/admin/api/v1/tickets`, { params: params }).then(res => res.data)
}
export const postTicket = (form) => {
  return axios.post(`${BASE_URL}/admin/api/v1/tickets/`, form).then(res => res.data);
}
export const editTicket = (data, method) => {   // method = edit, complete, delete
  return axios.patch(`${BASE_URL}/admin/api/v1/ticket/${data._id}/${method}`, data).then(res => res.data);
}

// user audit log
export const getAuditLog = (id) => {
  return axios.get(`${BASE_URL}/admin/api/v1/user-log/${id}`).then(res => res.data)
}