import { useRouter } from 'next/router'
import Link from 'next/link'

import Badge from 'react-bootstrap/Badge';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Timeclock from './admin/timeclock';
import Notifications from './admin/notifications';
import { useState, useRef, useEffect } from 'react';

const MainNavBar = (props) => {

  const { 
    auth,
    pageCounts, 
    tabCounts, 
    notifications, 
    postLogout, 
    timeclockHandler, 
    seenNotificationsHandler
  } = props;

  const { permissions } = auth;

  const router = useRouter();

  const [ activeDropdown, setActiveDropdown ] = useState("");
  const [ randomNum, setRandomNum ] = useState(Math.floor(Math.random()));

  const handleSelect = (eventKey) => {
    // dont check hrefs
    if (eventKey === activeDropdown) {
      setActiveDropdown("");
    } else {
      setActiveDropdown(eventKey);
    };
  };

  const [ newNotifications, setNewNotifications ] = useState({
    count: 0,
    ids: [],
    oldIds: []
  });

  useEffect(() => {
    if (notifications.length > 0) {
      let newCount = 0;
      let newIds = []
      let oldIds = newNotifications.ids.map(x => x);
      notifications.forEach(notif => {
        if (!notif.seen) {
          newCount++
          newIds.push(notif._id.toString())
        }
      });
      setNewNotifications({
        count: newCount,
        ids: newIds,
        oldIds: oldIds
      })
    }
  }, [notifications])

  const [ updatedCountTab, setUpdatedCountTab ] = useState('');
  const prevCountsRef = useRef();
  const playSound = (path) => {
    let sound;
    
    sound = new Audio(path);

    if (typeof sound !== 'undefined') {
      sound.play().then((s) => { 
        // setUpdatedCountTab('');
      }).catch(err => {
        console.error(err)
        // setUpdatedCountTab('');
      });
    }
  }
  useEffect(() => {
    if (
        permissions && updatedCountTab && (
          (permissions.apps.view && updatedCountTab === "applications") ||
          (permissions.inventory.view && updatedCountTab === "inventory") ||
          (permissions.bvas.view && updatedCountTab === "bvas") ||
          (permissions.teamAlerts.view && updatedCountTab === "teamAlerts") ||
          (permissions.workOrders.view && updatedCountTab === "workOrders") ||
          (permissions.serviceIncome.view && updatedCountTab === "serviceIncome") ||
          (permissions.contacts.view && updatedCountTab === "contacts")
        )
    ) {
      playSound(updatedCountTab === "applications" ? "/static/assets/sounds/notify-1.m4a" : "/static/assets/sounds/notify-3.m4a")
      setTimeout(() => {
        setUpdatedCountTab('');
      }, 1000);
    }
  }, [updatedCountTab])
  
  useEffect(() => {
    if (prevCountsRef.current) {
      // counts: {
      //   applications: 0,
      //   inventory: 0,
      //   bvas: 0,
      //   teamAlerts: 0,
      //   workOrders: 0,
      //   serviceIncome: 0
      // },
      const prevCounts = prevCountsRef.current;
      const workingKey = Object.keys(prevCounts).find(key => prevCounts[key] < tabCounts[key]);
      if (typeof workingKey !== "undefined") {
        setUpdatedCountTab(workingKey)
      }
    }
    prevCountsRef.current={...tabCounts};
  }, [tabCounts])

  const getDropdownContents = () => {
    switch (activeDropdown) {
      case "notifications": 
        return (
          <>
            <NavDropdown.ItemText className="text-center text-light">
              Notifications
            </NavDropdown.ItemText>
            <Notifications notifications={notifications} prevNotificationIds={newNotifications.oldIds} NavDropdown={NavDropdown} />
            <NavDropdown.ItemText className="text-center w-100 text-muted pb-0"><small><em>entries expire after 30 days</em></small></NavDropdown.ItemText>
          </>
        );
      case "timeclock": 
        return <>
          <NavDropdown.ItemText className="text-center text-light">
            Timeclock
          </NavDropdown.ItemText>
          <NavDropdown.Header className="px-4">
            <Timeclock 
              auth={auth}
              timeclockHandler={timeclockHandler}
            />
          </NavDropdown.Header>
          { auth.payPeriodStartDate && 
            <>
              <NavDropdown.Divider className="mt-auto" />
              <Link
                href="/users/[id]/timesheet"
                as={`/users/${auth._id}/timesheet`}
                passHref
                legacyBehavior>
                <NavDropdown.Item active={false} className="text-center">View Timesheets</NavDropdown.Item>
              </Link>
            </>
          }
        </>;
      case "profile":
        return <>
          <NavDropdown.ItemText className="text-center h5">
            {auth.role}{' '}<span className="text-capitalize">{auth.details.nickname ? auth.details.nickname : auth.details.firstName}</span>
          </NavDropdown.ItemText>
          <Link
            href="/users/[id]/edit/[page]"
            as={`/users/${auth._id}/edit/profile`}
            passHref
            legacyBehavior>
            <NavDropdown.Item active={router.query.page === "profile"} className="text-center">Edit Profile</NavDropdown.Item>
          </Link>
          <Link
            href="/users/[id]/edit/[page]"
            as={`/users/${auth._id}/edit/notifications`}
            passHref
            legacyBehavior>
            <NavDropdown.Item active={router.query.page === "notifications"} className="text-center">Notifcation Settings</NavDropdown.Item>
          </Link>
          { auth.role === "Super Admin" && 
            <>
              <NavDropdown.Divider />
              <Link
                href="/users/[id]/edit/[page]"
                as={`/users/${auth._id}/edit/permissions`}
                passHref
                legacyBehavior>
                <NavDropdown.Item active={router.query.page === "permissions"} className="text-center mb-2 text-gold">Manage Permissions</NavDropdown.Item>
              </Link>
            </>
          }
          <NavDropdown.Divider className="mt-auto" />
          <NavDropdown.Item onClick={postLogout} className="text-center">Logout</NavDropdown.Item>
        </>;
    };
  };

  //close on click outside
  const wrapperRef = useRef(null);
  function handleClickOutside(event) {
    if (activeDropdown && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setActiveDropdown("");
    };
  };
  
  useEffect(() => {
    if (activeDropdown === "notifications" && newNotifications.count > 0) {
      seenNotificationsHandler();
    }
    // Bind the event listener
    document.removeEventListener("click", handleClickOutside);
    document.addEventListener("click", handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
    };
  }, [activeDropdown]);

  // COND RENDER TABS
  const allowedTabs = [];
  if (permissions && permissions.leads.view) {
    allowedTabs.push({ name: "Leads", countKey: "leads", count: tabCounts.leads, path: "/tabs/leads"});
  }
  if (permissions && permissions.apps.view) {
    allowedTabs.push({ name: "Applications", countKey: "applications", count: tabCounts.applications, path: "/tabs/applications"});
  }
  if (permissions && permissions.customers.view) {
    allowedTabs.push({ name: "Customers", countKey: "customers", count: tabCounts.customers, path: "/tabs/customers"});
  }
  if (permissions && permissions.inventory.view) {
    allowedTabs.push({ name: "Inventory", countKey: "inventory", count: tabCounts.inventory,  path: "/tabs/inventory"});
  }
  if (permissions && permissions.bvas.view) {
    allowedTabs.push({ name: "BVAs", countKey: "bvas", count: tabCounts.bvas,  path: "/tabs/bvas"});
  }
  if (permissions && permissions.teamAlerts.view) {
    allowedTabs.push({ name: "Team Alerts", countKey: "teamAlerts", count: tabCounts.teamAlerts,  path: "/tabs/team-alerts"});
  }
  if (permissions && permissions.workOrders.view) {
    allowedTabs.push({ name: "Work Orders (Body)", countKey: "workOrders", count: tabCounts.workOrders,  path: "/tabs/work-orders"});
  }
  if (permissions && permissions.serviceIncome.view) {
    allowedTabs.push({ name: "Fixed Ops / Service Income", countKey: "serviceIncome", count: tabCounts.serviceIncome,  path: "/tabs/service-income"});
  }
  if (permissions && permissions.contacts.view) {
    allowedTabs.push({ name: "Contacts", tabCounts: 0,  path: "/tabs/contacts"});
  }
  if (auth.role === "Super Admin") {
    allowedTabs.push({ name: "Users", path: "/tabs/users"});
  }

  return (
    <div id="main-nav" style={{flex: "0 0 180px"}} className="position-relative h-100 bg-dark text-light d-print-none">
      <div  
        id="main-nav-dropdown" 
        className={`dropdown-menu dropdown-menu-dark ${activeDropdown ? "show" : ""}`}
        // style={{backgroundColor: "#212529"}}
      >
        {getDropdownContents()}
      </div>
      {/* <Container fluid> */}

        {auth._id && (
          <div className="h-100 d-flex flex-column py-3">
            <div className="d-flex flex-column justify-content-start overflow-auto">
              <Link href={auth._id ? "/" : "#"}>
                <img className="d-inline-block" src="/static/logo.svg" alt="1800MyAutos" />
              </Link>

              <div className="overflow-auto">
                <hr/>
                <div ref={wrapperRef} className="position-relative">
                  <Nav  className="flex-row justify-content-around align-items-center text-center" onSelect={handleSelect}>
                    <Nav.Link active={activeDropdown==="profile"} eventKey="profile" className="p-0">
                      <div style={{width: "2em", fontSize: '0.9rem' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                        </svg>
                      </div>
                    </Nav.Link>
                    { auth.payPeriodStartDate &&
                      <Nav.Link active={activeDropdown==="timeclock"} eventKey="timeclock" className="p-0">
                        <div style={{width: "2em"}}>
                          {
                            (auth.timesheet.clockIn && !auth.timesheet.clockOut) ? (
                              (auth.timesheet.breakIn && !auth.timesheet.breakOut) ? (
                                <i className={`bi bi-alarm-fill`} style={{fontSize:"1.4rem"}}></i>
                              ) : (
                                <i className={`bi bi-clock-fill`} style={{fontSize:"1.4rem"}}></i>
                              )
                            ) : <i className={`bi bi-clock`} style={{fontSize:"1.4rem"}}></i>
                          }
                        </div>
                      </Nav.Link>
                    }
                    <Nav.Link active={activeDropdown==="notifications"} eventKey="notifications" className="p-0">
                      <div style={{width: "2em"}}>
                        <span className={`position-relative ${newNotifications.count > 0 ? "text-warning" : ""}`}>
                          <i className={`bi bi-bell-fill`} style={{fontSize:"1.4rem"}}></i>
                          { newNotifications.count > 0 &&
                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                              {newNotifications.count}
                              <span className="visually-hidden">unread messages</span>
                            </span>
                          }
                        </span>
                      </div>
                    </Nav.Link>
                  </Nav>
                </div>
                <hr/>
                <Nav className="d-flex flex-column">
                  <Link href="/" passHref legacyBehavior>
                    <Nav.Link active={router.pathname === "/"} className="main-menu-items">
                        <div style={{width: '2em', height: '2.2em'}}>
                          <i className={`bi bi-speedometer2`} style={{fontSize:"1.6em"}}></i>
                        </div>
                        <div>Dashboard</div>
                    </Nav.Link>
                  </Link>
                  <Link href="/calendar" passHref legacyBehavior>
                    <Nav.Link active={router.pathname === "/calendar"} className="main-menu-items">

                      <div className="position-relative d-flex flex-column justify-content-center align-items-center w-100">
                          
                        {pageCounts.calendar > 0 && (
                          <Badge style={{fontSize:"0.8rem"}} className="position-absolute text-start end-0" bg={router.pathname === "/calendar" ? "dark" : "primary"} title="Number of events today.">
                            {pageCounts.calendar.toString()}
                          </Badge>
                        )}

                        <div style={{width: '2em'}}>
                          <i className={`bi bi-calendar3-week`} style={{fontSize:"1.5em"}}></i>
                        </div>
                        <div>Calendar</div>

                      </div>
                      

                    </Nav.Link>
                  </Link>
                  <Link href="/deals" passHref legacyBehavior>
                    <Nav.Link active={router.pathname === "/deals"} className="main-menu-items">

                        <div className="position-relative d-flex flex-column justify-content-center align-items-center w-100">
                            
                          {pageCounts.deals > 0 && (
                            <Badge style={{fontSize:"0.8rem"}} className="position-absolute text-start end-0" bg={router.pathname === "/deals" ? "dark" : "primary"} title="Total for the month.">
                              {pageCounts.deals.toString()}
                            </Badge>
                          )}

                          <div style={{width: '2em'}}>
                            <i className={`bi bi-kanban`} style={{fontSize:"1.6em"}}></i>
                          </div>
                          <div>Deals</div>

                        </div>
                    </Nav.Link>
                  </Link>
                  <Link href="/tickets" passHref legacyBehavior>
                    <Nav.Link active={router.pathname === "/tickets"} className="main-menu-items">
                      <div className="position-relative d-flex flex-column justify-content-center align-items-center w-100">
                        
                        {pageCounts.tickets > 0 && (
                          <Badge style={{fontSize:"0.8rem"}} className="position-absolute end-0" bg={router.pathname === "/tickets" ? "dark" : "danger"} title="Number of opem tickets.">
                            {pageCounts.tickets.toString()}
                          </Badge>
                        )}
                        <div style={{width: '2em'}}>
                          <i className={`bi bi-megaphone-fill`} style={{fontSize:"1.6em"}}></i>
                        </div>
                        <div>Urgent</div>
  
                      </div>
                      
                    </Nav.Link>
                  </Link>
                  <hr />
                  {
                    allowedTabs.map(tab =>
                      {
                        const isActive = tab.path && router.asPath.includes(tab.path);
                        return (
                          <Link
                            key={`tab-list-item-${tab.name}`}
                            href="/tabs/[tab]"
                            as={tab.path}
                            passHref
                            shallow={false}
                            legacyBehavior
                          >
                            <Nav.Link 
                              title={tab.name}
                              disabled={!tab.path} 
                              active={isActive} 
                              className={`main-menu-items tabs${ tab.countKey === updatedCountTab ? " alert-notify" : ''}`}
                            >
                                <div>{tab.name}</div>
                                {tab.count > 0 && 
                                  <Badge  bg={isActive ? "dark" : "secondary"}>
                                    {tab.count.toString()}
                                  </Badge>
                                }
                            </Nav.Link>
                          </Link>
                        )
                      }
                    )
                  }
                  <hr />
                  <Link href="/inventory" passHref legacyBehavior>
                    <Nav.Link active={router.pathname === "/inventory"} className="main-menu-items">
                      <div style={{width: '2em'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi" width="1.4em" viewBox="0 0 24 24">
                          <path d="M23.5 7c.276 0 .5.224.5.5v.511c0 .793-.926.989-1.616.989l-1.086-2h2.202zm-1.441 3.506c.639 1.186.946 2.252.946 3.666 0 1.37-.397 2.533-1.005 3.981v1.847c0 .552-.448 1-1 1h-1.5c-.552 0-1-.448-1-1v-1h-13v1c0 .552-.448 1-1 1h-1.5c-.552 0-1-.448-1-1v-1.847c-.608-1.448-1.005-2.611-1.005-3.981 0-1.414.307-2.48.946-3.666.829-1.537 1.851-3.453 2.93-5.252.828-1.382 1.262-1.707 2.278-1.889 1.532-.275 2.918-.365 4.851-.365s3.319.09 4.851.365c1.016.182 1.45.507 2.278 1.889 1.079 1.799 2.101 3.715 2.93 5.252zm-16.059 2.994c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5.672 1.5 1.5 1.5 1.5-.672 1.5-1.5zm10 1c0-.276-.224-.5-.5-.5h-7c-.276 0-.5.224-.5.5s.224.5.5.5h7c.276 0 .5-.224.5-.5zm2.941-5.527s-.74-1.826-1.631-3.142c-.202-.298-.515-.502-.869-.566-1.511-.272-2.835-.359-4.441-.359s-2.93.087-4.441.359c-.354.063-.667.267-.869.566-.891 1.315-1.631 3.142-1.631 3.142 1.64.313 4.309.497 6.941.497s5.301-.184 6.941-.497zm2.059 4.527c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5.672 1.5 1.5 1.5 1.5-.672 1.5-1.5zm-18.298-6.5h-2.202c-.276 0-.5.224-.5.5v.511c0 .793.926.989 1.616.989l1.086-2z"/>
                        </svg>
                      </div>
                      <div>Online Inventory</div>
                    </Nav.Link>
                  </Link>
                  <Link href="/ringcentral" passHref legacyBehavior>
                    <Nav.Link active={router.pathname === "/ringcentral"} className="main-menu-items">
                      <div style={{width: '2em'}}>
                        <i className={`bi bi-chat-fill`} style={{fontSize:"1.4em"}}></i>
                      </div>
                      <div>Ringcentral</div>
                    </Nav.Link>
                  </Link>
                </Nav>
              </div>
            </div>
            <div className="mt-auto">
              <hr />
              {
                auth.role === "Super Admin" && <Nav className="d-flex flex-column mb-2">
                  <Link href="/admin/superpowers" passHref legacyBehavior>
                    <Nav.Link active={router.pathname === "/admin/superpowers"} className="main-menu-items mt-auto text-gold">
                      <div>Superpowers</div>
                    </Nav.Link>
                  </Link>
                </Nav>
              }
            </div>
          </div>
        )}
    </div>
  );
}

export default MainNavBar;