import { useRouter } from 'next/router'
import Link from 'next/link'


import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

import Timeclock from './admin/timeclock';
import Notifications from './admin/notifications';
import { useState, useRef, useEffect } from 'react';

const MobileNavBar = (props) => {

  const { 
    auth,
    pageCounts,
    tabCounts,
    notifications, 
    postLogout, 
    timeclockHandler, 
    seenNotificationsHandler
  } = props

  const router = useRouter();

  const [ activeDropdown, setActiveDropdown ] = useState("");
  

  const handleSelect = (eventKey) => {
    // dont check hrefs
    if (eventKey === activeDropdown) {
      setActiveDropdown("");
    } else {
      setActiveDropdown(eventKey);
    };
  };

  const [ newNotifications, setNewNotifications ] = useState({
    count: 0,
    ids: [],
    oldIds: []
  });

  useEffect(() => {
    if (notifications.length > 0) {
      let newCount = 0;
      let newIds = []
      let oldIds = newNotifications.ids.map(x => x);
      notifications.forEach(notif => {
        if (!notif.seen) {
          newCount++
          newIds.push(notif._id.toString())
        }
      });
      setNewNotifications({
        count: newCount,
        ids: newIds,
        oldIds: oldIds
      })
    }
  }, [notifications])

  //prank
  const [ prankActivated, setPrankActivated ] = useState(false);
  const triggerPrank = (event) => {
    event.preventDefault();
    const d = Math.random();
    if (d < 0.01) {
      // <1% chance
      setPrankActivated(true);
    }
  }
  
  useEffect(() => {
    if (prankActivated) {
      setTimeout(() => {
        setPrankActivated(false);
      }, 3000);
    }
  }, [prankActivated])

  const getDropdownContents = () => {
    switch (activeDropdown) {
      case "notifications": 
        return (
          <>
            <NavDropdown.ItemText className="text-center text-light">
              Notifications
            </NavDropdown.ItemText>
            <Notifications notifications={notifications} prevNotificationIds={newNotifications.oldIds} NavDropdown={NavDropdown} />
            <NavDropdown.ItemText className="text-center w-100 text-muted pb-0"><small><em>entries expire after 30 days</em></small></NavDropdown.ItemText>
          </>
        );
      case "timeclock": 
        return <>
          <NavDropdown.ItemText className="text-center text-light">
            Timeclock
          </NavDropdown.ItemText>
          <NavDropdown.Header className="px-4">
            <Timeclock 
              auth={auth}
              timeclockHandler={timeclockHandler}
            />
          </NavDropdown.Header>
          { auth.payPeriodStartDate && 
            <>
              <NavDropdown.Divider className="mt-auto" />
              <Link
                href="/users/[id]/timesheet"
                as={`/users/${auth._id}/timesheet`}
                passHref
                legacyBehavior>
                <NavDropdown.Item active={false} className="text-center">View Timesheets</NavDropdown.Item>
              </Link>
            </>
          }
        </>;
      case "profile":
        return <>
          <NavDropdown.ItemText className="text-center h5">
            {auth.role}{' '}<span className="text-capitalize">{auth.details.nickname ? auth.details.nickname : auth.details.firstName}</span>
          </NavDropdown.ItemText>
          <Link
            href="/users/[id]/edit/profile"
            as={`/users/${auth._id}/edit/profile`}
            passHref
            legacyBehavior>
            <NavDropdown.Item active={router.query.page === "profile"} className="text-center">Edit Profile</NavDropdown.Item>
          </Link>
          <Link
            href="/users/[id]/edit/notifications"
            as={`/users/${auth._id}/edit/notifications`}
            passHref
            legacyBehavior>
            <NavDropdown.Item active={router.query.page === "notifications"} className="text-center">Notifcation Settings</NavDropdown.Item>
          </Link>
          { auth.role === "Super Admin" && 
            <>
              <NavDropdown.Divider />
              <Link
                href="/users/[id]/edit/permissions"
                as={`/users/${auth._id}/edit/permissions`}
                passHref
                legacyBehavior>
                <NavDropdown.Item active={router.query.page === "permissions"} className="text-center mb-2 text-gold">Manage Permissions</NavDropdown.Item>
              </Link>
            </>
          }
          <NavDropdown.Divider className="mt-auto" />
          <NavDropdown.Item onClick={postLogout} className="text-center">Logout</NavDropdown.Item>
        </>;
    };
  };

  //close on click outside
  const wrapperRef = useRef(null);
  function handleClickOutside(event) {
    if (activeDropdown && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setActiveDropdown("");
    };
  };
  
  useEffect(() => {
    if (activeDropdown === "notifications" && newNotifications.count > 0) {
      seenNotificationsHandler();
    }
    // Bind the event listener
    document.removeEventListener("click", handleClickOutside);
    document.addEventListener("click", handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
    };
  }, [activeDropdown]);

  // COND RENDER TABS
  const { permissions } = auth;
  const allowedTabs = [];
  if (permissions && permissions.leads.view) {
    allowedTabs.push({ name: "Leads", countKey: "leads", count: tabCounts.customers, path: "/tabs/leads"});
  }
  if (permissions && permissions.apps.view) {
    allowedTabs.push({ name: "Applications", count: tabCounts.applications, path: "/tabs/applications"});
  }
  if (permissions && permissions.customers.view) {
    allowedTabs.push({ name: "Customers", countKey: "customers", count: tabCounts.customers, path: "/tabs/customers"});
  }
  if (permissions && permissions.inventory.view) {
    allowedTabs.push({ name: "Inventory", count: tabCounts.inventory,  path: "/tabs/inventory"});
  }
  if (permissions && permissions.bvas.view) {
    allowedTabs.push({ name: "BVAs", count: tabCounts.bvas,  path: "/tabs/bvas"});
  }
  if (permissions && permissions.teamAlerts.view) {
    allowedTabs.push({ name: "Team Alerts", count: tabCounts.teamAlerts,  path: "/tabs/team-alerts"});
  }
  if (permissions && permissions.workOrders.view) {
    allowedTabs.push({ name: "Work Orders (Body)", count: tabCounts.workOrders,  path: "/tabs/work-orders"});
  }
  if (permissions && permissions.serviceIncome.view) {
    allowedTabs.push({ name: "Fixed Ops / Service Income", count: tabCounts.serviceIncome,  path: "/tabs/service-income"});
  }
  if (permissions && permissions.contacts.view) {
    allowedTabs.push({ name: "Contacts", count: tabCounts.contacts,  path: "/tabs/contacts"});
  }
  if (auth.role === "Super Admin") {
    allowedTabs.push({ name: "Users", path: "/tabs/users"});
  }

  // FOR HOLIDAY
  const [ randomNum, setRandomNum ] = useState(Math.floor(Math.random()));
  
  return (
    <Navbar id="mobile-nav" bg="dark" variant="dark" expand={false} className="d-print-none" collapseOnSelect>
      <Container fluid>
        <div className="position-relative h-100 d-flex" title={"Tony Ratani wuz here"}>
          <Link href="/" passHref legacyBehavior>
            <Navbar.Brand className={`p-0 h-100 position-relative${auth._id ? "" : " m-auto "}${prankActivated ? " prank-btn" : ""}`}>
                <img className="d-inline-block h-100" src="/static/logo.svg" alt="1800MyAutos" />
            </Navbar.Brand>
        </Link>

        </div>

        {auth._id && (
          <>      
            <Navbar.Toggle aria-controls="main-navbar-nav" />
            <Navbar.Offcanvas
              // show={show}
              // onHide={() => setShow(false)}
              id="main-navbar-nav"
              aria-labelledby="main-navbar-nav-label"
              placement="end"
              className="bg-dark text-light"
              
            >
              <Offcanvas.Header closeButton closeVariant="white" className="pb-0">
                <Offcanvas.Title>Navigation</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="pt-2">
                <div ref={wrapperRef} className="position-relative">
                  <Nav  className="flex-row justify-content-around align-items-center text-center">
                    <Nav.Link active={activeDropdown==="notifications"} className="p-0" onClick={(e) => { e.stopPropagation(); handleSelect("notifications"); }}>
                      <div style={{width: "2em"}}>
                        <span className={`position-relative ${newNotifications.count > 0 ? "text-warning" : ""}`}>
                          <i className={`bi bi-bell-fill`} style={{fontSize:"1.4em"}}></i>
                          { newNotifications.count > 0 &&
                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                              {newNotifications.count}
                              <span className="visually-hidden">unread messages</span>
                            </span>
                          }
                        </span>
                      </div>
                    </Nav.Link>
                    { auth.payPeriodStartDate &&
                      <Nav.Link active={activeDropdown==="timeclock"} className="p-0" onClick={(e) => { e.stopPropagation(); handleSelect("timeclock"); }}>
                        <div style={{width: "2em"}}>
                          {
                            (auth.timesheet.clockIn && !auth.timesheet.clockOut) ? (
                              (auth.timesheet.breakIn && !auth.timesheet.breakOut) ? (
                                <i className={`bi bi-alarm-fill`} style={{fontSize:"1.4em"}}></i>
                              ) : (
                                <i className={`bi bi-clock-fill`} style={{fontSize:"1.4em"}}></i>
                              )
                            ) : <i className={`bi bi-clock`} style={{fontSize:"1.4em"}}></i>
                          }
                        </div>
                      </Nav.Link>
                    }
                    <Nav.Link active={activeDropdown==="profile"} className="p-0" onClick={(e) => { e.stopPropagation(); handleSelect("profile"); }}>
                      <div style={{width: "2em"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1.7em" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                        </svg>
                      </div>
                    </Nav.Link>
                  </Nav>
                  {activeDropdown && <div 
                      id="main-nav-dropdown-mobile" 
                      className={`dropdown-menu dropdown-menu-dark ${activeDropdown ? "show" : ""}`}
                    >
                      {getDropdownContents()}
                  </div>}
                </div>
                <hr/>
                <Nav className="text-start ms-auto align-items-start ps-4 gap-3">
                  <Link href="/" passHref legacyBehavior>
                    <Nav.Link active={router.pathname === "/"} style={{width: '100%', height: '2em'}} className="main-menu-items mobile">
                        <div style={{width: '2em'}}><i className={`bi bi-speedometer2`} style={{fontSize:"1.6em"}}></i></div>
                        <div>Dashboard</div>
                    </Nav.Link>
                  </Link>
                  <Link href="/calendar" passHref legacyBehavior>
                    <Nav.Link active={router.pathname === "/calendar"} style={{width: '100%', height: '2em'}} className="main-menu-items mobile">
                      <div style={{width: '2em'}}>
                        <i className={`bi bi-calendar3-week`} style={{fontSize:"1.5em"}}></i>
                      </div>
                      <div>Calendar</div>
                      {pageCounts.calendar > 0 && <div style={{fontSize:"0.8rem"}} className="ms-auto me-3">{pageCounts.calendar.toString()}</div>}
                    </Nav.Link>
                  </Link>
                  <Link href="/deals" passHref legacyBehavior>
                    <Nav.Link active={router.pathname === "/deals"} style={{width: '100%', height: '2em'}} className="main-menu-items mobile">
                      <div style={{width: '2em'}}>
                        <i className={`bi bi-kanban`} style={{fontSize:"1.6em"}}></i>
                      </div>
                      <div>Deals</div>
                      {pageCounts.deals > 0 && <div style={{fontSize:"0.8rem"}} className="ms-auto me-3">{pageCounts.deals.toString()}</div>}
                    </Nav.Link>
                  </Link>
                  <Link href="/tickets" passHref legacyBehavior>
                    <Nav.Link active={router.pathname === "/tickets"} style={{width: '100%', height: '2em'}} className="main-menu-items mobile">
                      <div style={{width: '2em'}}>
                        <i className={`bi bi-megaphone-fill`} style={{fontSize:"1.6em"}}></i>
                      </div>
                      <div>Urgent</div>
                      {pageCounts.tickets > 0 && <div style={{fontSize:"0.8rem"}} className="ms-auto me-3">{pageCounts.tickets.toString()}</div>}
                    </Nav.Link>
                  </Link>
                  <Link href="/inventory" passHref legacyBehavior>
                    <Nav.Link active={router.pathname === "/inventory"} style={{width: '100%', height: '2em'}} className="main-menu-items mobile">
                      <div style={{width: '2em'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi" width="1.6em" viewBox="0 0 24 24">
                          <path d="M23.5 7c.276 0 .5.224.5.5v.511c0 .793-.926.989-1.616.989l-1.086-2h2.202zm-1.441 3.506c.639 1.186.946 2.252.946 3.666 0 1.37-.397 2.533-1.005 3.981v1.847c0 .552-.448 1-1 1h-1.5c-.552 0-1-.448-1-1v-1h-13v1c0 .552-.448 1-1 1h-1.5c-.552 0-1-.448-1-1v-1.847c-.608-1.448-1.005-2.611-1.005-3.981 0-1.414.307-2.48.946-3.666.829-1.537 1.851-3.453 2.93-5.252.828-1.382 1.262-1.707 2.278-1.889 1.532-.275 2.918-.365 4.851-.365s3.319.09 4.851.365c1.016.182 1.45.507 2.278 1.889 1.079 1.799 2.101 3.715 2.93 5.252zm-16.059 2.994c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5.672 1.5 1.5 1.5 1.5-.672 1.5-1.5zm10 1c0-.276-.224-.5-.5-.5h-7c-.276 0-.5.224-.5.5s.224.5.5.5h7c.276 0 .5-.224.5-.5zm2.941-5.527s-.74-1.826-1.631-3.142c-.202-.298-.515-.502-.869-.566-1.511-.272-2.835-.359-4.441-.359s-2.93.087-4.441.359c-.354.063-.667.267-.869.566-.891 1.315-1.631 3.142-1.631 3.142 1.64.313 4.309.497 6.941.497s5.301-.184 6.941-.497zm2.059 4.527c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5.672 1.5 1.5 1.5 1.5-.672 1.5-1.5zm-18.298-6.5h-2.202c-.276 0-.5.224-.5.5v.511c0 .793.926.989 1.616.989l1.086-2z"/>
                        </svg>
                      </div>
                      <div>Online Inventory</div>
                    </Nav.Link>
                  </Link>
                  <Link href="/ringcentral" passHref legacyBehavior>
                    <Nav.Link active={router.pathname === "/ringcentral"} style={{width: '100%', height: '2em'}} className="main-menu-items mobile">
                      <div style={{width: '2em'}}>
                        <i className={`bi bi-chat-fill`} style={{fontSize:"1.4em"}}></i>
                      </div>
                      <div>Ringcentral</div>
                    </Nav.Link>
                  </Link>
                </Nav>
                <hr />
                {
                  allowedTabs.map(tab =>
                    <Link
                      key={`tab-list-item-${tab.name}`}
                      href="/tabs/[tab]"
                      as={tab.path}
                      passHref
                      shallow={false}
                      legacyBehavior>
                      <Nav.Link disabled={!tab.path} active={tab.path && router.asPath.includes(tab.path)} className="main-menu-items mobile tabs">
                          <div>{tab.name}</div>
                          {tab.count > 0 && <div style={{fontSize:"0.8rem"}}>{tab.count.toString()}</div>}
                      </Nav.Link>
                    </Link>
                  )
                }
                {
                  auth.role === "Super Admin" && <Nav className="d-flex flex-column mb-2">
                    <Link href="/admin/superpowers" passHref legacyBehavior>
                      <Nav.Link active={router.pathname === "/admin/superpowers"} className="main-menu-items mt-auto text-gold">
                        <div>Superpowers</div>
                      </Nav.Link>
                    </Link>
                  </Nav>
                }

              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </>
        )}
      </Container>
    </Navbar>
  );
}

export default MobileNavBar;