import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import moment from 'moment';

const Notifications = (props) => {
    const { NavDropdown, notifications, prevNotificationIds } = props
    return (
        <Container fluid className="overflow-y-gradient" style={{overflowY: "scroll", maxHeight: "60vh"}}>
            { notifications.length > 0 ? (
                <>
                {notifications.map(notification => {
                    const getIcon = () => {
                        switch (notification.type) {
                            // sms, comment, appointment, followUp, timeclock
                            case "sms": {
                                return <i className={`bi bi-chat-square-text`} style={{fontSize:"1.6em"}}></i>
                            }
                            case "comment": {
                                return <i className={`bi bi-chat-square-text`} style={{fontSize:"1.6em"}}></i>
                            }
                            case "appointment": {
                                return <i className={`bi bi-calendar4-event`} style={{fontSize:"1.6em"}}></i>
                            }
                            case "followUp": {
                                return <i className={`bi bi-telephone`} style={{fontSize:"1.6em"}}></i>
                            }
                            case "timeclock": {
                                return <i className={`bi bi-clock`} style={{fontSize:"1.6em"}}></i>
                            }
                        }
                    }
                    const splitMsg = notification.message.split(":")
                    return (
                        <NavDropdown.ItemText key={notification._id.toString()} style={prevNotificationIds.includes(notification._id.toString()) ? { backgroundColor: "#3d4c65", borderRadius:'0' } : {}} className={`py-2 border-bottom w-100 text-light`}>
                            <Row className="align-items-center">
                                <Col xs={2} className="text-end">
                                    {getIcon()}
                                </Col>
                                <Col className="ms-2 text-start">
                                    <strong className="text-capitalize">{splitMsg[0]}</strong><br/>
                                    <span className="text-capitalize">{splitMsg[1]}</span><br/>
                                    {splitMsg[2] && <><span className="text-capitalize small">{splitMsg[2]}</span><br/></> }
                                    <small className="text-info">{moment(notification.dateTime).fromNow()}</small>
                                </Col>
                            </Row>
                        </NavDropdown.ItemText>
                    )
                })}
                </>
            ) : (
                <NavDropdown.ItemText className="text-center w-100 text-light pb-0"><small><em>wuw, such empty.</em></small></NavDropdown.ItemText>
            )}
        </Container>
    )
}

export default Notifications;