import App from 'next/app'
import io from 'socket.io-client'
import Link from 'next/link'
import Head from 'next/head'
import SSRProvider from 'react-bootstrap/SSRProvider';

import MainNavBar from '../components/mainNavBar';
import MobileNavBar from '../components/mobileNavBar';

import moment from 'moment';

import Router from 'next/router';

import initialState from '../defaultState/global';

import { 
  getLogin, 
  postLogin, 
  postLogout,
  postUserTimesheet,
  postUserPinApp,
  deleteUserPinApp,
  postPushSubscription,
  getUserNotifications,
  editUserNotifications,
  getBlastStatus,
  getPageCount,
  getTabPageCount,
  getAppVersion } from '../actions';

import "bootstrap-icons/font/bootstrap-icons.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import '../styles/index.scss';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Alert from 'react-bootstrap/Alert';

import Modal from '../components/modal';
import WelcomeBox from '../components/admin/welcomeBox';

const defaultState = initialState;

class CarDealerApp extends App {
  constructor(props) {
    super(props);
    this.state = typeof structuredClone !== "undefined" ? structuredClone(defaultState) : JSON.parse(JSON.stringify(defaultState));   // POLYFILL
    this.state.auth = {...this.state.auth, ...props.loginDetails};
    this.state.pageCounts= props.pageCounts;
    this.state.tabCounts= props.tabPageCounts;
    this.state.disableNavBar = props.disableNavBar;
    this.state.isMobile = props.isMobile;
    this.state.version = props.version;
  };

  // TODO: execute here getInitialProps and pass this data to your page

  static async getInitialProps(appContext) {
    const { ctx } = appContext;
    const { req, res, query } = ctx;

    let disableNavBar = false;
    if (typeof query.disableNavBar !== "undefined" && query.disableNavBar.toLowerCase() === "true") {
      disableNavBar=true;
    }
    
    if (res) {
      const isMobile=req.useragent ? req.useragent.isMobile : false;
      const cookie = req.headers && req.headers.hasOwnProperty("cookie") ? req.headers.cookie : '';
      const isServerCookies = req ? { cookie: cookie } : null;
      const appVersion = typeof process.env.npm_package_version !== "undefined" ? process.env.npm_package_version : ""

      return await Promise.all([
        getLogin(isServerCookies).catch(err => { 
          const errMsg = err?.response?.data ?? err
          console.error(errMsg);
          return defaultState.auth;
        }),
        getPageCount([
          "dashboard",
          "calendar",
          "deals",
          "tickets"
        ], isServerCookies).catch(err => { 
          const errMsg = err?.response?.data ?? err
          console.error(errMsg);
          return defaultState.pageCounts;
        }),
        getTabPageCount([
          "applications",
          "inventory",
          "bvas",
          "teamAlerts",
          "workOrders",
          "serviceIncome"
        ], isServerCookies).catch(err => {
          const errMsg = err?.response?.data ?? err
          console.error(errMsg);
          return defaultState.tabCounts;
        }),
        App.getInitialProps(appContext)
      ]).then(([ loginDetails, pageCounts, tabPageCounts, appProps ]) => {
        return {
          version: appVersion,
          isMobile,
          loginDetails,
          pageCounts,
          tabPageCounts,
          disableNavBar,
          ...appProps
        }
      }).catch(err => console.error(err));
    } else {
      const appProps = await App.getInitialProps(appContext);
      return { ...appProps }
    }

    // Executing getInitialProps of page you are navigated to
  };

  // UPDATE COUNTS
  // updateTabPageCount = (key, newCount) => {    

  //   this.setState({
  //     ...this.state,
  //     counts: {
  //       ...this.state.counts,
  //       [key]: newCount ? newCount : this.state.counts[key]+1 // for new apps send null count from server
  //     }
  //   });
  // }

  triggerRefresh = (navText, pageTitleText) => {
    const randomIntFromInterval = (min, max) => { // min and max included 
      return Math.floor(Math.random() * (max - min + 1) + min);
    }
    // only trigger if not started
    if (!this.state.reloadTimer) {
      this.setState({
        ...this.state,
        navBarAlert: navText,
        pageTitle: pageTitleText,
        reloadTimer: randomIntFromInterval(60,180)
      })
    }
  }

  // SOCKET HANDLER
  incWSHandler = (data) => {

    switch (data.name) {
      case "new app version": {
        this.triggerRefresh("New Update", "* NEW VERSION AVAILABLE")
        break;
      }

      case "midnight refresh": {
        this.triggerRefresh("Balls on your face", "If you're seeing this. There are balls on your face.")
        break;
      }

      case "new sms": {
        if (this.state.auth && this.state.auth._id && this.state.auth.notificationSettings.sms) {

          const playSound = (path) => {
            let sound = new Audio(path);
        
            if (typeof sound !== 'undefined') {
              sound.play().then((s) => { 
              }).catch(err => {
                console.error(err)
              });
            }
          }


          playSound("/static/assets/sounds/cust-reaching-out.mp3")

          data.record.show = true;
          const cloneToast = [...this.state.toasts, data.record];
          this.setState({
            ...this.state,
            toasts: cloneToast
          })
        }
        break;
      }

      case "blast progress": {
        const { name, ...otherDetails } = data;
        if (!this.state.blastToast.forceHide || otherDetails.progress === 100 || otherDetails.canceled ) { 
          const forceHide = this.state.forceHide ? ( ( otherDetails.progress === 100 || otherDetails.canceled ) ? false : true ) : false;  // is hiding, once complete/cancel, reset back to false
          this.setState({
            ...this.state,
            blastToast: {
              ...this.state.blastToast,
              forceHide: forceHide,
              show: true,
              ...otherDetails
            }
          });
        }

        break;
      }

      case "new timesheet": {
        if (this.state.auth && data.userId.toString() === this.state.auth._id.toString()) {
          this.timeclockUpdater()
        }
        break
      }
      case "delete timesheet": {
        if (this.state.auth && data.userId.toString() === this.state.auth._id.toString()) {
          this.timeclockUpdater(data.timesheetId)
        }
        break
      }
      case "update timesheet": {
        if (this.state.auth && data.userId.toString() === this.state.auth._id.toString()) {
          this.setState({
            ...this.state,
            auth: {
              ...this.state.auth,
              timesheet: data.timesheet
            }
          })
        }
        break
      }
      case "force logout": {
        if (this.state.auth) {
          this.postLogoutHandler();
        };
        break;
      }
      case "update user": {
        if (this.state.auth && data.user._id.toString() === this.state.auth._id.toString()) {
          if (data.disabled) {
            this.postLogoutHandler();
          } else {
            this.setState({
              ...this.state,
              auth: {
                ...this.state.auth,
                details: {
                  ...this.state.auth.details,
                  ...data.user.details
                },
                role: data.user.role,
                payRates: data.user.payRates,
                payPeriodStartDate: data.user.payPeriodStartDate,
                payFrequency: data.user.payFrequency,
                permissions: data.user.permissions
              }
            });
          };
        };
        break;
      }
      case "user notification": {
        if (this.state.auth && data.userId.toString() === this.state.auth._id.toString()) {
          getUserNotifications().then(notifications => {
            this.setState({
              ...this.state,
              notifications: notifications
            });
          });
        };
        break;
      }
      case "update count": {
        if (this.state.auth && this.state.auth._id) {
          getPageCount(data.keys).then(counts => {
            this.setState({
              ...this.state, 
              pageCounts: {
                ...this.state.pageCounts,
                ...counts
              }
            });
          })
        };
        break;
      }

      case "update tab count": {
        if (this.state.auth && this.state.auth._id) {

          getTabPageCount([
            "applications",
            "inventory",
            "bvas",
            "teamAlerts",
            "workOrders",
            "serviceIncome"
          ]).then(counts => {
            this.setState({
              ...this.state, 
              tabCounts: {
                ...this.state.tabCounts,
                ...counts
              }
            });
          })

          // count keys: 
          // "applications",
          // "inventory",
          // "bvas",
          // "teamAlerts",
          // "workOrders",
          // "serviceIncome"
          // this.updateTabPageCount(data.key, data.count)
        };
        break;
      }
    }
  }

  setOffline = () => {
    console.log('We are offline!');
    this.setState({
      ...this.state,
      online: false
    });
  };
  setOnline = () => {
    console.log('We are online!');
    this.setState({
      ...this.state,
      online: true
    });
  };
  handleVisibilityChange = () => {
    const state = document.visibilityState;
    if (state === "hidden") {
      // your PWA is now in the background
      console.log("document hidden")
      this.setState({
        ...this.state,
        visible: false
      });
    }
  
    if (state === "visible") { 
      // your PWA is now in the foreground
      console.log("document visibile")
      this.setState({
        ...this.state,
        visible: true
      });
    }
  }

  // DID MOUNT
  async componentDidMount() {
    window.addEventListener('offline', this.setOffline);
    window.addEventListener('online', this.setOnline);
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  
    let notifications = this.state.auth && this.state.auth._id ? await getUserNotifications().catch(err => { console.log(err); return []; }) : [];
  
    const checkVersion = () => {
      getAppVersion().then(ver => {
        if (ver !== this.state.version) {
          this.triggerRefresh("New Update", "* NEW VERSION AVAILABLE")
        }
      })
    };
  
    // Initialize newState with common properties
    const newState = {
      ...this.state,
      intervalId:     setInterval(checkVersion, 1000 * 60),
      notifications:  notifications,
      socket:         io({transports: ['websocket']}),
      pwa: {
        ...this.state.pwa,
      }
    };
  
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator && window.workbox !== undefined) {
      navigator.serviceWorker.ready.then(reg => {
        if (reg) {
          reg.pushManager.getSubscription().then(sub => {
            
            newState.pwa.registration = reg;
            if (sub && !(sub.expirationTime && Date.now() > sub.expirationTime - 5 * 60 * 1000)) {
              newState.pwa.subscription = sub;
              newState.pwa.isSubscribed = true;
            }
            
            // dont comment out becasue this is async. the setState at the end of the func will run first before this one.
            this.setState(newState);
          })
        }
      }).catch((err) => {
        console.error(err);
  
        // No need to call this.setState here anymore.
        // Changes to newState will be applied below
      })
    }
    
    // We call this.setState once, outside of the conditionals.
    // This applies the newState, regardless of whether an error was caught or not.
    console.log("called set state didmount")
    this.setState(newState);
  }  

  // WILL UNMOUNT
  componentWillUnmount () {
    window.removeEventListener('offline', this.setOffline);
    window.removeEventListener('online', this.setOnline);

    document.removeEventListener("visibilitychange", this.handleVisibilityChange)

    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  }

  // DID UPDATE
  componentDidUpdate(prevProps, prevState) {
    // test toast
    // if (this.state.toasts.length === 0) {
    //   const test = {
    //     id: "25",
    //     from: {
    //       phoneNumber: "+12143068440"
    //     },
    //     subject: "test"
    //   }
  
    //   const cloneToast = [...this.state.toasts, test];
    //   this.setState({
    //     ...this.state,
    //     toasts: cloneToast
    //   })
    // }

    if // either offline to online OR invisible to visible while on mobile and isAuth
      (
        ((prevState.visible !== this.state.visible) && this.state.visible) || 
        ((prevState.online !== this.state.online) && this.state.online) && this.state.auth && this.state.auth._id
      ) {
      if (this.state.isMobile) {

        if (this.state.blastToast.progress > 0) {
          //if blast toast stuck - so check blast details, and auth0
          Promise.all([getLogin(), getBlastStatus(), getUserNotifications()]).then(results => {
            const loginDetails = results[0];
            const blastDetails = results[1];
            const notifications = results[2];

            this.setState({
              ...this.state, 
              auth: {...this.state.auth, ...loginDetails},
              blastToast: {
                ...this.state.blastToast,
                ...blastDetails
              },
              notifications: notifications,
            })
          }).catch(err => {
            const errMsg = err?.response?.data ?? err
            console.error(errMsg);
            alert(errMsg);
          })
        } else {
          //not stuck so just check login and timesheets
          Promise.all([getLogin(), getUserNotifications()]).then(results => {
            const loginDetails = results[0];
            const notifications = results[1];

            this.setState({
              ...this.state, 
              auth: {...this.state.auth, ...loginDetails},
              notifications: notifications,
            })
          }).catch(err => {
            const errMsg = err?.response?.data ?? err
            console.error(errMsg);
            alert(errMsg);
          })
        }
      }
    }

    if (
      (prevState.blastToast.progress !== this.state.blastToast.progress || prevState.blastToast.canceled !== this.state.blastToast.canceled) &&
      (this.state.blastToast.progress === 100 || this.state.blastToast.canceled)
      ) {
        setTimeout(() => {
          this.setState({
            ...this.state,
            blastToast: {
              ...this.state.blastToast,
              show: false
            }
          })
          
        }, 5000)
    } else if (prevState.blastToast.show !== this.state.blastToast.show && !this.state.blastToast.show) {

        setTimeout(() => {
          this.setState({
            ...this.state,
            blastToast: typeof structuredClone !== "undefined" ? structuredClone(defaultState.blastToast) : JSON.parse(JSON.stringify(defaultState.blastToast))  // POLYFILL
          })
        }, 5000)
    }

    //PWA
    if (this.state.auth && prevState.auth &&
      (
        (prevState.auth._id !== this.state.auth._id) ||
        (this.state.auth._id && prevState.auth.departments.toString() !== this.state.auth.departments.toString() )
      )
      ) {
      if (this.state.auth._id) {
        // just logged in
        Promise.all([
          getBlastStatus(),
          getPageCount([
            "dashboard",
            "calendar",
            "deals",
            "tickets"
          ]),
          getTabPageCount([
            "applications",
            "inventory",
            "bvas",
            "teamAlerts",
            "workOrders",
            "serviceIncome"
          ]),
          getUserNotifications()
        ]).then(results => {
          this.setState( s => ({
            ...s, 
            blastToast: {
              ...s.blastToast,
              ...results[0]
            },
            pageCounts: results[1],
            tabCounts: results[2],
            notifications: results[3],
            welcomeData: {
              ...s.welcomeData,
              showModal: ( s.welcomeData.teamAlerts.length || s.welcomeData.tickets.length ) && true
            }
          }));
        });

        if (this.state.pwa.registration && !this.state.pwa.isSubscribed) {
          const base64ToUint8Array = (base64) => {
            const padding = '='.repeat((4 - (base64.length % 4)) % 4)
            const b64 = (base64 + padding).replace(/-/g, '+').replace(/_/g, '/')
          
            const rawData = window.atob(b64)
            const outputArray = new Uint8Array(rawData.length)
          
            for (let i = 0; i < rawData.length; ++i) {
              outputArray[i] = rawData.charCodeAt(i)
            }
            return outputArray
          }
    
          // not subscribed or expired
          this.state.pwa.registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: base64ToUint8Array(process.env.NEXT_PUBLIC_WEB_PUSH_PUBLIC_KEY)
          }).then(sub => {
            if (sub && !(sub.expirationTime && Date.now() > sub.expirationTime - 5 * 60 * 1000)) {
    
              postPushSubscription({
                userId: this.state.auth._id,
                subscription: sub
              }).then(() => {
                console.log('web push subscribed!')
  
                // SETSTATE
                this.setState({
                  ...this.state, 
                  pwa: {
                    ...this.state.pwa,
                    subscription: sub,
                    isSubscribed: true
                  }
                })
  
              })
  
            }
  
          }).catch(err => console.error(err))
        }


      } else {
        // is a logout
        Router.push('/login');
      }
    }

    // socket
    if (prevState.socket !== this.state.socket) {
      if (this.state.socket) {
        this.state.socket.io.on("error", (error) => {
          console.error(error)
        });
        this.state.socket.on("global outgoing data", this.incWSHandler);
        this.state.socket.on("outgoing timesheet data", this.incWSHandler);
        this.state.socket.on("global outgoing timesheet data", this.incWSHandler);
      }
    };

    if (prevState.navBarAlert !== this.state.navBarAlert) {
      // new version
      const countDown = () => {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.reloadTimer - 1;
        
        // Check if we're at zero.
        if (seconds <= 0) { 
          Router.reload()
        } else {
          this.setState({
            ...this.state,
            reloadTimer: seconds
          })
        }
      }
      setInterval(countDown, 1000);
    }
  };

  // DID CATCH
  componentDidCatch(error) {
    window.Rollbar?.error(error)
  }

  postLoginHandler = async (loginCredentials) => {
    return postLogin(loginCredentials).then(results => {

      this.setState({
        ...this.state, 
        auth: {...this.state.auth, ...results.auth},
        welcomeData: {
          ...this.state.welcomeData,
          ...results.welcomeData
        }
      });

    })
  };

  postLogoutHandler = () => {
    return postLogout().then(() => {
      const cloneDefaultState = typeof structuredClone !== "undefined" ? structuredClone(defaultState) : JSON.parse(JSON.stringify(defaultState));   // POLYFILL
      this.setState({
        ...cloneDefaultState,
        isMobile:   this.state.isMobile,
        version:    this.state.version,
        socket:     this.state.socket
      });
    }).catch(err => console.error(err))
  };

  // TIMECLOCK handlers
  timeclockHandler = (inOrOut) => {
    let timesheet = { ...this.state.auth.timesheet };
    const userId = this.state.auth._id
    const currentTime = moment().format('HH:mm')

    const postTimeSheet = () => {
      postUserTimesheet(userId, timesheet, true).then((retrievedTimesheet) => {
        this.setState({
          ...this.state,
          auth: {
            ...this.state.auth,
            timesheet: retrievedTimesheet
          }
        })

        if (this.state.socket) {

          // emit
          this.state.socket.emit("incoming timesheet data", {
            name: "update timesheet", 
            userId: userId,
            timesheet: retrievedTimesheet
          });

        }

      }).catch(err => {
        const errMsg = err?.response?.data ?? err
        console.error(errMsg);
        alert(errMsg);
      })
    }

    const convertTotalHours = (ms) => {
      let hours = Math.floor(ms/1000/60/60);
      let mins = Math.floor((ms/1000/60/60 - hours)*60);
      
      mins < 10 ? mins = `0${mins}`: mins = `${mins}`
      
      return `${hours}:${mins}`
    }

    getLogin().then((loginDetails) => { // chech sync with server

      if (typeof loginDetails.timesheet === "undefined") {
        // is a logout
        return Router.push('/login');
      }

      const currentTimesheet = loginDetails.timesheet
      const currentTimesheetId = currentTimesheet._id
      
      if (currentTimesheetId !== this.state.auth.timesheet._id) {
        this.setState({
          ...this.state,
          auth: {
            ...this.state.auth,
            timesheet: currentTimesheet
          }
        })
        return alert("Resync'd with server. Try Again.")
      }

      switch (inOrOut) {
        case "clockIn": {
            timesheet.clockIn = moment().format('HH:mm')
            postTimeSheet()
          break;
        }
        case "clockOut": {
          if (timesheet.clockIn === currentTime) {
            return alert("cannot clock out yet")
          }

          const breakMilliseconds = (timesheet.breakIn && timesheet.breakOut) ? moment(timesheet.breakOut, "HH:mm").diff(moment(timesheet.breakIn, "HH:mm"), "milliseconds", true) : 0 // ex. 21660000ms = 6 hours
          const breakTime = (timesheet.breakIn && timesheet.breakOut) ? convertTotalHours(breakMilliseconds) : false  // ex. "06:30"

          if (moment(currentTime, "HH:mm").diff(moment(timesheet.clockIn, "HH:mm"), "hours", true) > 5) {
            if (!breakTime ||  moment.duration(breakMilliseconds).asHours() < 1) {
              timesheet.breakTime = "1:00"
            } else {
              timesheet.breakTime = breakTime;
            }
          } else {
            timesheet.breakTime = "0:00"
          }

          timesheet.clockOut = currentTime;
          postTimeSheet()
          
          break;
        }
        case "breakIn": {
          timesheet.breakIn = currentTime
          postTimeSheet()
          break;
        }
        case "breakOut": {
          if (timesheet.breakIn === currentTime) {
            return alert("cannot break out yet")
          }
          timesheet.breakOut = currentTime;
          postTimeSheet()
          break;
        }
      }
    })
  }
  timeclockUpdater = (deletedTimesheetFormId) => {
    getLogin().then((loginDetails) => {

      if (typeof loginDetails.timesheet === "undefined") {
        // is a logout
        return Router.push('/login');
      }
      const timesheet = loginDetails.timesheet
      const timesheetId = timesheet._id || deletedTimesheetFormId

      if (timesheetId === this.state.auth.timesheet._id || !this.state.auth.timesheet._id ) {
        this.setState({
          ...this.state,
          auth: {
            ...this.state.auth,
            timesheet: {
              ...this.state.auth.timesheet,
              ...timesheet
            }
          }
        })
      }
    });
  }

  // NOTIFICATION handlers
  seenNotificationsHandler = () => {
    return editUserNotifications().then(notifications => {
      this.setState({
        ...this.state,
        notifications: notifications
      });
    });
  }

  // USER Pin handler
  userPinHandler = (form, method, pinType) => {
    if (method === "post") {
      return postUserPinApp(form.id, form.makeSuper, pinType).then(updatedPins => {
        this.setState({
          ...this.state,
          auth: {
            ...this.state.auth,
            pinned: updatedPins
          }
        });
      })
    } else if (method === "delete") {
      return deleteUserPinApp(form.id, pinType).then(updatedPins => {
        this.setState({
          ...this.state,
          auth: {
            ...this.state.auth,
            pinned: updatedPins
          }
        });
      })
    }
  };

  render() {
    // Component holds page you are navigating to
    const { 
      Component, 
      pageProps
    } = this.props;

    const handleHideToast = (record, index) => {
      const cloneToast = this.state.toasts.map((rec => {
        if (rec.id === record.id) {
          rec.show = false;
        }
        return rec;
      }))
      this.setState({
        ...this.state,
        toasts: cloneToast
      })

      setTimeout(() => {
        this.setState({
          ...this.state,
          toasts: this.state.toasts.filter(x => x.id !== record.id)
        })
      }, 2000)

    }

    function formatPhoneNumber(phoneNumberString) {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        var intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ')', match[3], '-', match[4]].join('');
      }
      return phoneNumberString;
    }

    const getTCClass = (tc) => {
      return tc.clockIn ? (
        tc.clockOut ? (
          "bg-success"
        ) : "bg-warning"
      ) : "bg-danger"
    }

    return (
      <SSRProvider id="page-container">
        <Head>
          <title>{(this.state.navBarAlert && this.state.reloadTimer >= 0) ? `* Refresh in ${this.state.reloadTimer} seconds for New Update ` : this.state.pageTitle}</title>
          
          <link rel="apple-touch-icon" sizes="180x180" href="/static/favicon/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/static/favicon/favicon-32x32.png"/>
          <link rel="icon" type="image/png" sizes="16x16" href="/static/favicon/favicon-16x16.png"/>
          <link rel="manifest" href="/static/favicon/site.webmanifest"/>
          <link rel="mask-icon" href="/static/favicon/safari-pinned-tab.svg" color="#5bbad5"/>
          <link rel="shortcut icon" href="/static/favicon/favicon.ico" />
          
          <meta httpEquiv="content-language" content="en-us" />

          <meta name='application-name' content="1800MyAutos Dashboard" />
          <meta name='apple-mobile-web-app-capable' content='yes' />
          <meta name='apple-mobile-web-app-status-bar-style' content='default' />
          <meta name='apple-mobile-web-app-title' content="1800MyAutos Dashboard" />
          <meta name='description' content="Admin dashboard for 1800myautos.com" />
          <meta name='format-detection' content='telephone=no' />
          <meta name='mobile-web-app-capable' content='yes' />
          <meta name='theme-color' content='#212529' />
          <meta name="msapplication-TileColor" content="#da532c"/>

          <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width, user-scalable=no' />

        </Head>
          
        <main id="content-wrap">
          <Modal
            show={this.state.welcomeData.showModal}
            size="lg"
            handleClose={() => this.setState( s => ( 
              { 
                ...s, 
                welcomeData: { ...defaultState.welcomeData }
              } 
            ) ) }
          >
            <WelcomeBox
              teamAlerts={this.state.welcomeData.teamAlerts}
              tickets={this.state.welcomeData.tickets}
              handleClose={() => this.setState( s => ( 
                { 
                  ...s, 
                  welcomeData: { ...defaultState.welcomeData }
                } 
              ) ) }
            />
          </Modal>
          
          { this.state.auth && this.state.auth._id && this.state.auth.payPeriodStartDate &&
            <div style={{height: '.3em', width: '100%'}} className={`d-print-none position-relative ${getTCClass(this.state.auth.timesheet)}`}>
              {/* <button style={{position: 'absolute', top: '200%', left: '50%', transform: 'translate(-50%, -50%)'}} onClick={() => {}}>Open Dropdown</button> */}
            </div>
          }

          {this.state.navBarAlert && (
            <div className="d-print-none d-flex justify-content-center position-absolute w-100 mt-2">
              <Alert style={{zIndex: '1'}} className="p-2 text-center" variant="danger"><i className="bi bi-info-circle"></i>{" "}<strong>{(this.state.navBarAlert && this.state.reloadTimer >= 0 )? `New Update: Refreshing in ${this.state.reloadTimer} seconds` : this.state.navBarAlert}</strong></Alert>
            </div>
          )}
          {!this.state.online && (
            <div className="d-print-none d-flex justify-content-center position-absolute w-100 mt-2">
                <Alert style={{zIndex: '1'}} className="p-2 text-center" variant="warning"><i className="bi bi-info-circle"></i> Network Offline.</Alert>
            </div>
          )}
          { this.state.auth && this.state.auth._id && !this.state.isMobile &&
            <ToastContainer className="position-fixed m-4" position={'bottom-end'} style={{zIndex: '1'}}>
                {this.state.toasts.length > 0 && this.state.toasts.map((record, i) => {
                  return (
                    <Toast 
                      key={record.id}
                      show={this.state.toasts[i].show}
                      onClose={() => handleHideToast(record, i)} 
                      delay={30000}
                      autohide
                    >
                      <Toast.Header className="align-middle">
                        <strong className="me-auto">{formatPhoneNumber(record.from.phoneNumber)}</strong>
                        {record.appName && <em><small>{' '}({record.appName})</small></em>}
                        <small className="ms-auto">{"now"}</small>
                      </Toast.Header>
                      <Toast.Body className="text-start">
                        {record.subject}
                      </Toast.Body>
                      <Toast.Body className="text-center">
                        <Link
                          key={record.id}
                          shallow={false}
                          href={{
                              pathname: '/ringcentral',
                              query: { conversationId: record.conversation.id },
                            }}
                          target="_blank">
                          
                            View Message
                          
                        </Link>
                      </Toast.Body>
                    </Toast>
                  );
                })
              }
              <Toast 
                show={this.state.blastToast.show && !this.state.blastToast.forceHide}
                onClose={() => this.setState(
                  { 
                    ...this.state,
                    blastToast: {
                      ...this.state.blastToast,
                      forceHide: true
                    }
                  }
                )}
                delay={3000}
                bg="dark"
              >
                <Toast.Header closeVariant="white" className="bg-dark text-white d-flex border-bottom border-secondary text-capitalize">
                    <div><strong>{`${this.state.blastToast.canceled ? "(Canceled)" : ""} ${this.state.blastToast.sender} started a blast `}</strong></div>
                    <div className="ms-auto">{this.state.blastToast.currentCount}/{this.state.blastToast.appCount}</div>
                </Toast.Header>
                <Toast.Body className="text-white">
                  <p>
                    <small>{this.state.blastToast.message}</small>
                  </p>
                  <ProgressBar variant={this.state.blastToast.canceled  ? "warning" : "primary"} className="mt-2" animated now={this.state.blastToast.progress} label={`${this.state.blastToast.progress}%`}/>
                </Toast.Body>
              </Toast>
            </ToastContainer>
          }
          
          { !this.state.isMobile ? (
            <Container fluid className="h-100">
              <Row className='h-100'>
                {this.state.auth && this.state.auth._id && !this.state.disableNavBar && (
                  <MainNavBar 
                    auth={this.state.auth} 
                    pageCounts={this.state.pageCounts}
                    tabCounts={this.state.tabCounts}
                    notifications={this.state.notifications}
                    postLogout={this.postLogoutHandler}
                    timeclockHandler={this.timeclockHandler} 
                    seenNotificationsHandler={this.seenNotificationsHandler}
                  />
                ) }

                <Col id="content" className="h-100 overflow-auto p-0">
                  <Component {...pageProps}
                    auth={this.state.auth}
                    online={this.state.online}
                    visible={this.state.visible}
                    isMobile={this.state.isMobile}
                    socket={this.state.socket}
                    blastToast={this.state.blastToast}
                    postLogin={this.postLoginHandler}
                    postLogout={this.postLogoutHandler}
                    userPinHandler={this.userPinHandler}
                  />
                </Col>
              </Row>
            </Container>
          ) : (
            <div className="h-100 d-flex flex-column">
             <div className="h-100 d-flex flex-column justify-content-start overflow-auto">
              { this.state.auth && this.state.auth._id && !this.state.disableNavBar &&
                <MobileNavBar 
                  auth={this.state.auth} 
                  pageCounts={this.state.pageCounts}
                  tabCounts={this.state.tabCounts}
                  notifications={this.state.notifications}
                  postLogout={this.postLogoutHandler}
                  timeclockHandler={this.timeclockHandler} 
                  seenNotificationsHandler={this.seenNotificationsHandler}
                />
              }
              <div id="content" className="h-100 overflow-auto">
                <Component {...pageProps}
                  auth={this.state.auth}
                  online={this.state.online}
                  visible={this.state.visible}
                  isMobile={this.state.isMobile}
                  socket={this.state.socket}
                  blastToast={this.state.blastToast}
                  postLogin={this.postLoginHandler}
                  postLogout={this.postLogoutHandler}
                  userPinHandler={this.userPinHandler}
                />
              </div>
            </div>
          </div>
          )}
        </main>
      </SSRProvider>
    );
  }
}


export default CarDealerApp;
