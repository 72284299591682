import permissions from './userPermissions'

export default {
    version: '',
    intervalId: null,
  
    online: true,
    visible: true,
    isMobile: false,
    
    socket: null,
  
    show: true,
  
    disableNavBar: false,
  
    navBarAlert: '',
    pageTitle: "1800MyAutos Dashboard",
    reloadTimer: 0,
  
    pageCounts: {
      dashboard: 0,
      calendar: 0,
      deals: 0,
      tickets: 0
    },
    tabCounts: {
      applications: 0,
      inventory: 0,
      bvas: 0,
      teamAlerts: 0,
      workOrders: 0,
      serviceIncome: 0
    },
  
    toasts: [
      // {
      //   id: 9344826004,
      //   conversation: {
      //     id: "25"
      //   },
      //   appName: "john snowdden",
      //   from: {
      //     phoneNumber: "+12143068440"
      //   },
      //   subject: "t2342342342"
      // }
    ],
    blastToast: {
      forceHide: false,
      show: false,
      progress: 0,
      currentCount: 0,
      appCount: 0,
      message: '',
      sender: '',
      canceled: false
    },
  
    auth: {
      _id: "",
      details: {
        firstName: "",
        lastName: "",
        fullName: "",
        nickname: "",
        username: "",
      },
      departments: [],
      role: "",
      payRates: [],
      payPeriodStartDate: null,
      payFrequency: "",
      timesheet: {
        breakTime: "",
        breakIn: "",
        breakOut: "",
        clockIn: "",
        clockOut: "",
        comment: "",
        date: "",
        _id: ""
      },
      pinned: {
        apps: [],
        inventory: []
      },
      notificationSettings: {
        sms: false,
        comment: false,
        appointment: false,
        timeclock: false,
        application: false,
        repair: false,
        teamAlert: false,
        bva: false,
        email: false
      },
      hiddenCalendars: [],
      permissions
    },
  
    notifications: [],
  
    pwa: {
      isSubscribed: false,
      subscription: null,
      registration: null
    },

    welcomeData: {
        showModal:  false,
        teamAlerts: [],
        tickets:    []
    }
}