import moment from 'moment';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ListGroup from 'react-bootstrap/ListGroup';

const Timeclock = (props) => {
    const { auth, timeclockHandler } = props

    const timeclockStatus = () => {
        if (!auth.timesheet.clockIn) {
            return (
                <div className="d-grid">
                    <Button className="rounded-0" type="button" variant="secondary" onClick={() => timeclockHandler("clockIn")}>{"Clock In"}</Button>
                </div>
            )
        } else if (auth.timesheet.clockIn && !auth.timesheet.breakIn && !auth.timesheet.clockOut) {
            return (
                <ButtonGroup className="w-100">
                    <Button 
                        className="rounded-0"
                        type="button" 
                        variant="warning" 
                        onClick={() => 
                            timeclockHandler("clockOut")
                    }>
                        {"Clock Out"}
                    </Button>
                    <Button 
                        className="rounded-0"
                        type="button" 
                        variant="secondary"
                        onClick={() => timeclockHandler("breakIn")}
                    >
                        <img src="/static/assets/lunch.svg" alt="Break" />
                    </Button>
                </ButtonGroup>
            )
        } else if (auth.timesheet.breakIn && !auth.timesheet.breakOut) {
            return (
                <div className="d-grid">
                    <Button className="rounded-0" type="button" variant="danger" onClick={() => timeclockHandler("breakOut")}>{"Return from break"}</Button>
                </div>
            )
        } else if (auth.timesheet.clockIn && auth.timesheet.breakIn && auth.timesheet.breakOut && !auth.timesheet.clockOut) {
            return (
                <div className="d-grid">
                    <Button className="rounded-0"type="button" variant="warning" onClick={() => timeclockHandler("clockOut")}>{"Clock Out"}</Button>
                </div>
            )
        } else {
            return (
                <ListGroup className="justify-content-center">
                    <ListGroup.Item>Clock in: <span className="float-end">{`${moment(auth.timesheet.clockIn, "HH:mm").format("h:mm A")}`}</span></ListGroup.Item>
                    <ListGroup.Item>Clock out: <span className="float-end">{`${moment(auth.timesheet.clockOut, "HH:mm").format("h:mm A")}`}</span></ListGroup.Item>
                    <ListGroup.Item>Break <small>(duration)</small>: <span className="float-end">{auth.timesheet.breakTime}</span></ListGroup.Item>
                </ListGroup>
            )
        }
    }

    return (
        <>
            { auth._id && auth.payPeriodStartDate && 
                (
                    timeclockStatus()
                )
            }

        </>
    )
}

export default Timeclock;