export default {
    dashboard: {
        statsAppsReceived: false,
        statsInventoryReady: false,
        statsInventorySold: false,
        statsInventoryGetBacks: false
    },
    deals: {
        return: false,
        delete: false,
        customer:       { view: false, edit: false },
        coCustomer:     { view: false, edit: false },
        unit:           { view: false, edit: false },
        lender:         { view: false, edit: false },
        downPayment:    { view: false, edit: false },
        netCheck:       { view: false, edit: false },
        tradeAcv:       { view: false, edit: false },
        payoff:         { view: false, edit: false },
        sold:           { view: false, edit: false },
        funded:         { view: false, edit: false },
        fundAmount:     { view: false, edit: false },
        reserve:        { view: false, edit: false },
        reserveAmount:  { view: false, edit: false },
        received:       { view: false, edit: false },
        interview:      { view: false, edit: false },
        employment:     { view: false, edit: false },
        booked:         { view: false, edit: false },
        invoiced:       { view: false, edit: false },
        tag:            { view: false, edit: false },
        title:          { view: false, edit: false },
        inspection:     { view: false, edit: false },
        source:         { view: false, edit: false },
        salesReps:      { view: false, edit: false },
        notes:          { view: false, edit: false }
    },
    leads: {
        view: false,
        edit: false,
        delete: false,
        comment: false,

        comments: false,
        
        markComplete: false,
        markUncomplete: false,

        addLead: false,

        printLead: false
    },
    apps: {
        view: false,
        edit: false,
        delete: false,
        comment: false,

        phone: false,
        followUp: false,
        ssn: false,
        email: false,
        dob: false,
        address: false,
        salesPerson: false,
        comments: false,
        
        markHotLead: false,
        markExcluded: false,

        viewStats: false,
        export: false,

        printApp: false
    },
    customers: {
        view: false,
        edit: false,
        delete: false,
        comment: false,

        comments: false,
        
        markComplete: false,
        markUncomplete: false,

        addCustomer: false,

        printCustomer: false
    },
    inventory: {
        view: false,
        edit: false,
        delete: false,
        comment: false,

        datePurchased: false,
        dateSold: false,
        dateFunded: false,
        status: false,
        eta: false,
        mileage: false,
        internetPrice: false,
        repairCost: false,
        totalRepairCost: false,
        originalCost: false,
        totalCost: false,
        bookValue: false,
        spreadValue: false,
        source: false,
        floor: false,
        comments: false,
        
        markReady: false,
        markUnready: false,
        markComplete: false,
        markUncomplete: false,
        markDead: false,

        addVehicle: false,
        viewStats: false,
        export: false,

        editEstMechReady: false,
        editEstBodyReady: false,
        editEstReady: false,
        editGps: false,
        editImages: false,
        editTitle: false,
        editInspection: false,
        editTeamAlerts: false,

        // addRepairs: false,
        // editRepairs: false,
        // deleteRepairs: false,

        locationItem: {
            view: false,
            add: false,
            editName: false,
            editSource: false,
            editStartDate: false,
            editEndDate: false,
            editComment: false,
            editStatus: false,
            editLaborCost: false,
            editPartsCost: false,
            editResponsibility: false,
            active: false,
            delete: false
        },
        shopItem: {
            view: false,
            add: false,
            editName: false,
            editSource: false,
            editStartDate: false,
            editEndDate: false,
            editComment: false,
            editStatus: false,
            editLaborCost: false,
            editPartsCost: false,
            editResponsibility: false,
            active: false,
            delete: false
        },
        expenseItem: {
            view: false,
            add: false,
            editName: false,
            editSource: false,
            editStartDate: false,
            editEndDate: false,
            editComment: false,
            editStatus: false,
            editLaborCost: false,
            editPartsCost: false,
            editResponsibility: false,
            active: false,
            delete: false
        },
        webItem: {
            view: false,
            add: false,
            editName: false,
            editSource: false,
            editStartDate: false,
            editEndDate: false,
            editComment: false,
            editStatus: false,
            editLaborCost: false,
            editPartsCost: false,
            editResponsibility: false,
            active: false,
            delete: false
        },
        warrantyItem: {
            view: false,
            add: false,
            editName: false,
            editSource: false,
            editStartDate: false,
            editEndDate: false,
            editComment: false,
            editStatus: false,
            editLaborCost: false,
            editPartsCost: false,
            editResponsibility: false,
            active: false,
            delete: false
        },
        gapItem: {
            view: false,
            add: false,
            editName: false,
            editSource: false,
            editStartDate: false,
            editEndDate: false,
            editComment: false,
            editStatus: false,
            editLaborCost: false,
            editPartsCost: false,
            editResponsibility: false,
            active: false,
            delete: false
        },


        exportCsv: false,
        printVehicle: false,
        printBva: false,
        printGps: false
    },
    teamAlerts: {
        view: false,
        edit: false,
        delete: false,
        comment: false,

        comments: false,
        concerningOthers: false,
        
        markComplete: false,
        markUncomplete: false,

        addTeamAlert: false,

        printTeamAlert: false,
        printTeamAlert: false
    },
    bvas: {
        view: false,
        edit: false,
        delete: false,
        comment: false,

        comments: false,
        
        markComplete: false,
        markUncomplete: false,

        addBva: false,

        printBva: false
    },
    workOrders: {
        view: false,
        edit: false,
        delete: false,
        comment: false,

        comments: false,
        
        markComplete: false,
        markUncomplete: false,

        addWorkOrder: false,

        addParts: false,
        editParts: false,
        deleteParts: false,

        printWorkOrder: false
    },
    serviceIncome: {
        view: false,
        edit: false,
        delete: false,
        comment: false,

        comments: false,
        
        markComplete: false,
        markUncomplete: false,

        addServiceIncome: false,

        addRepairs: false,
        editRepairs: false,
        deleteRepairs: false,

        exportCsv: false,

        printServiceIncome: false
    },
    contacts: {
        view: false,
        edit: false,
        delete: false,
        comment: false,

        comments: false,
        
        markComplete: false,
        markUncomplete: false,

        addContact: false,

        printContact: false
    },
    reports: {
        view: false
    }
}